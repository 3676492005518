<template>
  <div>
    <Head :title="`Editar usuario`" />
    
    <!-- Header -->
    <div class="flex items-center gap-4 mb-8">
      <button 
        class="p-3 text-white bg-teal-800 hover:bg-teal-900 rounded-lg transition-colors"
        title="Volver atrás"
        @click="back()"
      >
        <i class="fa fa-arrow-left"></i>
      </button>
      <h1 class="text-3xl font-bold">
        <Link 
          class="text-indigo-600 hover:text-indigo-700 transition-colors" 
          href="/users"
        >
          Usuarios
        </Link>
        <span class="text-gray-400 font-medium mx-2">/</span>
        <span>{{ form.first_name }} {{ form.last_name }}</span>
        <img v-if="user.photo" class="h-8 ml-2 inline-block rounded-full" :src="user.photo" />
      </h1>
    </div>

    <!-- Mensaje usuario eliminado -->
    <trashed-message v-if="user.deleted_at" class="mb-6" @restore="restore">
      Este usuario ha sido eliminado.
    </trashed-message>

    <!-- Formulario -->
    <div class="max-w-3xl bg-white rounded-lg shadow-sm border border-gray-200">
      <form @submit.prevent="update">
        <div class="p-8">
          <h2 class="text-lg font-bold mb-4">Información del usuario</h2>
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <text-input
              v-model="form.first_name"
              :error="form.errors.first_name"
              label="Nombre *"
              placeholder="Ingrese el nombre"
            />
            <text-input
              v-model="form.last_name"
              :error="form.errors.last_name"
              label="Apellido *" 
              placeholder="Ingrese el apellido"
            />
            <text-input
              v-model="form.email"
              :error="form.errors.email"
              label="Correo electrónico *"
              placeholder="ejemplo@correo.com"
            />
            <text-input
              v-model="form.password"
              :error="form.errors.password"
              type="password"
              autocomplete="new-password"
              label="Contraseña"
              placeholder="Dejar en blanco para mantener"
            />
            <select-input
              v-model="form.role"
              :error="form.errors.role"
              label="Rol *"
            >
              <option value="">Seleccionar rol</option>
              <option value="admin">Administrador</option>
              <option value="sales">Vendedor</option>
              <option value="storer">Almacenista</option>
              <option value="financial">Finanzas</option>
            </select-input>
            <select-input
              v-model="form.organization_id"
              :error="form.errors.organization_id"
              label="Tienda *"
            >
              <option value="">Seleccionar tienda</option>
              <option
                v-for="organization in organizations"
                :key="organization.id"
                :value="organization.id"
              >
                {{ organization.name }}
              </option>
            </select-input>
            <file-input
              v-model="form.photo"
              :error="form.errors.photo"
              type="file"
              accept="image/*"
              label="Foto de perfil"
            />
          </div>
        </div>

        <!-- Submit -->
        <div class="flex items-center justify-between px-8 py-4 bg-gray-50 border-t border-gray-100">
          <button 
            v-if="!user.deleted_at" 
            class="text-red-600 hover:text-red-700 font-medium transition-colors"
            type="button"
            @click="destroy"
          >
            <i class="fa fa-trash mr-2"></i>
            Eliminar usuario
          </button>
          <loading-button
            :loading="form.processing"
            class="btn-blue"
            type="submit"
          >
            <i class="fa fa-save mr-2"></i>
            Actualizar usuario
          </loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import FileInput from '@/Shared/FileInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import TrashedMessage from '@/Shared/TrashedMessage'

export default {
  name: 'UserEdit',

  components: {
    Head,
    Link,
    TextInput,
    FileInput,
    SelectInput,
    LoadingButton,
    TrashedMessage
  },

  layout: Layout,

  props: {
    user: {
      type: Object,
      required: true
    },
    organizations: {
      type: Object,
      required: true
    }
  },

  remember: 'form',

  data() {
    return {
      form: this.$inertia.form({
        _method: 'put',
        first_name: this.user?.first_name,
        last_name: this.user?.last_name,
        email: this.user?.email,
        password: '',
        role: this.user?.role,
        photo: null,
        organization_id: this.user?.organization_id
      })
    }
  },

  methods: {
    back() {
      window.history.back()
    },

    update() {
      this.form.post(`/users/${this.user.id}`, {
        onSuccess: () => this.form.reset('password', 'photo')
      })
    },

    destroy() {
      if (confirm('¿Está seguro que desea eliminar este usuario?')) {
        this.$inertia.delete(`/users/${this.user.id}`)
      }
    },

    restore() {
      if (confirm('¿Está seguro que desea restaurar este usuario?')) {
        this.$inertia.put(`/users/${this.user.id}/restore`)
      }
    }
  }
}
</script>
