<template>
  <Head title="Iniciar Sesión" />
  <div class="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-100 to-blue-200 p-4">
    <div class="w-full max-w-md">
      <!-- Logo -->
      <logo 
        class="block mx-auto w-full max-w-xs fill-blue-600 transition-transform hover:scale-105" 
        height="50"
      />

      <!-- Formulario de login -->
      <form 
        @submit.prevent="login" 
        class="mt-8 bg-white rounded-2xl shadow-2xl overflow-hidden transition-all duration-300 hover:shadow-3xl"
      >
        <div class="px-10 py-12">
          <!-- Encabezado -->
          <h1 class="text-center text-3xl font-bold text-gray-800">
            ¡Bienvenido de nuevo!
          </h1>
          <p class="text-center text-gray-600 mt-2">
            Ingresa tus credenciales para continuar
          </p>
          <div class="mt-6 mx-auto w-24 border-b-2 border-blue-500" />

          <!-- Campos del formulario -->
          <text-input
            v-model="form.email"
            :error="form.errors.email"
            class="mt-10"
            label="Correo electrónico"
            type="email"
            placeholder="ejemplo@correo.com"
            autofocus
            autocomplete="email"
            autocapitalize="off"
          />

          <text-input
            v-model="form.password"
            :error="form.errors.password"
            class="mt-6"
            label="Contraseña" 
            type="password"
            placeholder="Ingresa tu contraseña"
            autocomplete="current-password"
          />

          <!-- Checkbox recordar -->
          <label 
            class="flex items-center mt-6 select-none cursor-pointer group"
            for="remember"
          >
            <input
              id="remember"
              v-model="form.remember"
              class="w-4 h-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500 cursor-pointer"
              type="checkbox"
            />
            <span class="ml-2 text-sm text-gray-600 group-hover:text-gray-800">
              Mantener sesión iniciada
            </span>
          </label>
        </div>

        <!-- Footer con botón -->
        <div class="px-5 py-5 bg-gray-50 border-t border-gray-100">
          <loading-button
            :loading="form.processing"
            class="btn-blue text-lg font-medium transition-colors"
            type="submit"
          >
            {{ form.processing ? 'Iniciando sesión...' : 'Iniciar sesión' }}
          </loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import Logo from '@/Shared/Logo'
import TextInput from '@/Shared/TextInput'
import LoadingButton from '@/Shared/LoadingButton'

export default {
  name: 'Login',
  
  components: {
    Head,
    LoadingButton,
    Logo,
    TextInput,
  },

  data() {
    return {
      form: this.$inertia.form({
        email: '',
        password: '',
        remember: false,
      }),
    }
  },

  methods: {
    login() {
      this.form.post('/login', {
        onSuccess: () => {
          // Opcional: Mostrar mensaje de éxito
        },
        onError: () => {
          // Opcional: Manejar errores específicos
        }
      })
    },
  },
}
</script>
