<template>
  <div>
    <Head title="Ventas" />

    <!-- Header y Barra de herramientas -->
    <app-toolbar
      name="Venta"
      namePlural="Ventas" 
      createHref="/orders/create"
      :exportButtons="true"
      :hiddenCreate="user.role == 'storer' || user.role == 'financial'"
    >
      <!-- Filtros de búsqueda -->
      <template v-slot:search>
        <search-filter 
          v-model="form.search" 
          class="mr-4 w-full max-w-md" 
          @reset="reset"
        >
          <label class="block text-gray-700 mb-1">Estado:</label>
          <select 
            v-model="form.state"
            class="form-select w-full rounded-lg border-gray-300 focus:border-blue-500 focus:ring-blue-500"
          >
            <option :value="null">Todos</option>
            <option v-for="state in states" :key="state.key" :value="state.key">
              {{ state.name }}
            </option>
          </select>
        </search-filter>
      </template>

      <!-- Botones de exportación -->
      <template v-slot:exportArea>
        <div class="flex items-center gap-2">
          <a  
            v-if="user.role === 'financial' || user.role === 'admin' || user.role == 'sales'"  
            :href="`/ordersDaily`" 
            target="_blank" 
            class="p-2.5 bg-red-700 hover:bg-red-800 rounded-lg transition-colors text-white"
            title="Reporte diario"
          >
            <i class="fa fa-print"></i>
          </a>
          <Link 
            v-if="user.role === 'financial' || user.role === 'admin'" 
            target="_blank" 
            :href="`/ordersReportFinancial`" 
            class="p-2.5 bg-green-600 hover:bg-green-700 rounded-lg text-white transition-colors"
            title="Reporte financiero"
          >
            <i class="fa fa-file"></i>
          </Link>
        </div>
      </template>

      <!-- Botón POS -->
      <template v-slot:createMore>
        <Link 
          v-if="user.role !== 'storer' && user.role !== 'financial'" 
          class="btn-blue ml-2" 
          href="/pos"
        >
          <span>Crear</span>
          <span class="hidden md:inline">&nbsp;Venta POS</span>
        </Link>
      </template>
    </app-toolbar>

    <!-- Tabla de ventas -->
    <div class="bg-white rounded-lg shadow-sm overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr class="text-left bg-gray-50">
            <th class="py-4 px-6 font-semibold">#</th>
            <th class="py-4 px-6 font-semibold">Fecha</th>
            <th class="py-4 px-6 font-semibold">Estado</th>
            <th class="py-4 px-6 font-semibold">Observación</th>
            <th class="py-4 px-6 font-semibold">Cliente</th>
            <th class="py-4 px-6 font-semibold">Vendedor</th>
            <th class="py-4 px-6 font-semibold">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="order in orders.data" 
            :key="order.id" 
            :class="{
              'hover:bg-gray-50 focus-within:bg-gray-50': true,
              'bg-red-100': order.order_state === 'cancel'
            }"
          >
            <td class="border-t">
              <Link 
                :href="`orders/${order.id}`" 
                class="block px-6 py-4 hover:text-blue-600 transition-colors"
              >
                {{ order.number_order }}
              </Link>
            </td>
            <td class="border-t">
              <Link 
                :href="`orders/${order.id}`" 
                class="block px-6 py-4 hover:text-blue-600 transition-colors"
              >
                {{ order.date }}
              </Link>
            </td>
            <td class="border-t">
              <Link 
                :href="`orders/${order.id}`" 
                class="block px-6 py-4 hover:text-blue-600 transition-colors"
              >
                <span :class="{
                  'px-2 py-1 rounded text-sm font-medium': true,
                  'bg-yellow-100 text-yellow-800': order.order_state === 'estimate',
                  'bg-red-100 text-red-800': order.order_state === 'cancel',
                  'bg-blue-100 text-blue-800': order.order_state === 'request',
                  'bg-green-100 text-green-800': order.order_state === 'finish',
                  'bg-purple-100 text-purple-800': order.order_state === 'pay',
                  'bg-orange-100 text-orange-800': order.order_state === 'delivery'
                }">
                  {{ getStateName(order.order_state) }}
                </span>
              </Link>
            </td>
            <td class="border-t">
              <Link 
                :href="`orders/${order.id}`" 
                class="block px-6 py-4 hover:text-blue-600 transition-colors"
              >
                {{ order.comentary }}
              </Link>
            </td>
            <td class="border-t">
              <Link 
                :href="`orders/${order.id}`" 
                class="block px-6 py-4 hover:text-blue-600 transition-colors"
              >
                {{ order.client.name }}
              </Link>
            </td>
            <td class="border-t">
              <Link 
                :href="`orders/${order.id}`" 
                class="block px-6 py-4 hover:text-blue-600 transition-colors"
              >
                {{ order.responsible ? `${order.responsible.first_name} ${order.responsible.last_name}` : 'Transacción directa' }}
              </Link>
            </td>
            <td class="border-t">
              <Link 
                :href="`orders/${order.id}`" 
                class="block px-6 py-4 hover:text-blue-600 transition-colors"
              >
                {{ formatCurrency(order.total) }}
              </Link>
            </td>
          </tr>

          <tr v-if="!orders.data.length">
            <td class="px-6 py-8 text-center text-gray-500 border-t" colspan="7">
              No hay órdenes disponibles
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <pagination 
      v-if="orders.links" 
      :links="orders.links" 
      class="mt-6"
    />
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import { getState } from '@/utils'
import Pagination from '@/Shared/Pagination.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import throttle from 'lodash/throttle'
import pickBy from 'lodash/pickBy'
import AppToolbar from '../../Shared/AppToolbar.vue'

export default {
  name: 'OrdersIndex',

  components: {
    SearchFilter,
    Head,
    Icon,
    Link,
    Pagination,
    AppToolbar,
  },

  layout: Layout,

  props: {
    orders: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      form: {
        search: '',
        state: null
      },
      states: [
        { key: 'request', name: 'Pendiente por pago' },
        { key: 'estimate', name: 'Presupuesto' },
        { key: 'pay', name: 'Pagada' },
        { key: 'payRevition', name: 'Pago en revisión' },
        { key: 'dispatch', name: 'Despachada' },
        { key: 'delivery', name: 'En espera de despacho' },
        { key: 'finish', name: 'Finalizada' },
        { key: 'cancel', name: 'Anulada' }
      ]
    }
  },

  watch: {
    form: {
      deep: true,
      handler: throttle(function() {
        this.$inertia.get('/orders', pickBy(this.form), { 
          preserveState: true 
        })
      }, 300)
    }
  },

  methods: {
    reset() {
      this.form = {
        search: '',
        state: null
      }
    },

    back() {
      window.history.back()
    },

    getStateName(state) {
      const found = this.states.find(s => s.key === state)
      return found ? found.name : state
    },

    formatCurrency(value) {
      return new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP'
      }).format(value)
    }
  }
}
</script>
