<template>
  <div class="container">
    <Head :title="`Venta #${order.number_order ?? order.id} ${order.order_state === 'cancel' ? 'PEDIDO ANULADO' : ''}`" />
    
    <!-- Contenedor principal -->
    <div class="flex-col items-center p-8 text-center bg-white rounded-lg shadow-lg">
      
      <!-- Encabezado y estado -->
      <div class="mb-8">
        <i class="fa fa-check text-green-500 text-3xl mb-4"></i>
        <h1 class="text-2xl font-bold">
          Venta #{{ order.number_order }} creada correctamente
        </h1>

        <!-- Estado y acciones -->
        <div class="mt-6">
          <div v-if="order.order_state === 'request'" class="space-y-4">
            <div class="font-medium">
              <strong>Estado: </strong>
              <span class="ml-2" :class="{
                'text-red-600': order.order_state === 'cancel',
                'text-yellow-600': order.order_state === 'request',
                'text-green-600': order.order_state === 'finish',
                'text-blue-600': order.order_state === 'pay',
                'text-orange-600': order.order_state === 'delivery'
              }">
                {{ getOrderState(order.order_state) }}
              </span>
            </div>
            <button 
              class="px-4 py-2 text-red-600 hover:bg-red-50 rounded-lg transition-colors" 
              type="button"
              @click="cancelOrder"
            >
              <i class="fa fa-times mr-2"></i>
              Anular orden
            </button>
          </div>

          <div v-else class="space-y-3">
            <div class="flex justify-center gap-4">
              <button 
                class="px-4 py-2 text-blue-600 hover:bg-blue-50 rounded-lg transition-colors"
                @click="print('usd')"
              >
                <i class="fa fa-print mr-2"></i> 
                Imprimir USD
              </button>
              
              <button 
                class="px-4 py-2 text-blue-600 hover:bg-blue-50 rounded-lg transition-colors"
                @click="print('ves')"
              >
                <i class="fa fa-print mr-2"></i>
                Imprimir BsD
              </button>

              <button 
                v-if="cop_change"
                class="px-4 py-2 text-blue-600 hover:bg-blue-50 rounded-lg transition-colors"
                @click="print('cop')"
              >
                <i class="fa fa-print mr-2"></i>
                Imprimir COP
              </button>
            </div>

            <Link
              href="/pos" 
              class="inline-block px-4 py-2 text-green-600 hover:bg-green-50 rounded-lg transition-colors"
            >
              <i class="fa fa-plus mr-2"></i>
              Nueva venta
            </Link>
          </div>
        </div>
      </div>

      <!-- Contenido principal -->
      <div class="flex flex-col md:flex-row gap-8">
        
        <!-- Tabla de productos -->
        <div class="w-full md:w-8/12">
          <div class="overflow-x-auto">
            <table class="w-full">
              <thead>
                <tr class="text-left bg-gray-50">
                  <th class="p-4">Producto</th>
                  <th class="p-4">Descripción</th>
                  <th class="p-4">Cantidad</th>
                  <th class="p-4">Precio</th>
                  <th class="p-4">Total</th>
                </tr>
              </thead>

              <tbody>
                <tr 
                  v-for="(product, key) in order.products" 
                  :key="key"
                  :class="{'bg-blue-50': product.dispatch}"
                >
                  <td class="p-4 border-t">{{ product.product.name }}</td>
                  <td class="p-4 border-t">{{ product.product.description }}</td>
                  <td class="p-4 border-t text-center">{{ product.quantity }}</td>
                  <td class="p-4 border-t text-right">${{ formatPrice(product.price) }}</td>
                  <td class="p-4 border-t text-right">${{ formatPrice(product.price * product.quantity) }}</td>
                </tr>
              </tbody>

              <tfoot class="bg-gray-50 font-medium">
                <tr>
                  <td colspan="3" class="p-4 border-t"></td>
                  <td class="p-4 border-t text-right">Total:</td>
                  <td class="p-4 border-t text-right">
                    ${{ formatPrice(order.total) }}
                    <div class="text-sm text-gray-500">
                      BsD. {{ formatPrice((order.total - order.balance) * change) }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="3" class="p-4 border-t"></td>
                  <td class="p-4 border-t text-right">Deuda:</td>
                  <td class="p-4 border-t text-right text-red-600">
                    ${{ formatPrice(order.total - order.balance) }}
                    <div class="text-sm">
                      BsD. {{ formatPrice((order.total - order.balance) * change) }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="3" class="p-4 border-t"></td>
                  <td class="p-4 border-t text-right">Balance:</td>
                  <td class="p-4 border-t text-right text-green-600">
                    ${{ formatPrice(order.balance) }}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>

        <!-- Panel de pagos -->
        <div class="w-full md:w-4/12">
          <div v-if="['financial', 'admin', 'sales'].includes(role)" class="bg-gray-50 p-6 rounded-lg">
            <h2 class="text-xl font-bold mb-6">Pagos</h2>

            <payment-box 
              :quick="true"
              :payment_methods="payment_methods"
              :balance="order.total - order.balance"
              :change="change"
              :order="order"
            />

            <!-- Historial de pagos -->
            <div class="mt-8">
              <h3 class="font-medium mb-4">Historial de pagos</h3>
              
              <div class="space-y-3">
                <div 
                  v-for="pay in order.pays" 
                  :key="pay.id"
                  class="bg-white p-4 rounded-lg"
                >
                  <div class="flex justify-between items-center">
                    <span class="capitalize font-medium">{{ pay.method }}</span>
                    <span class="text-right">
                      ${{ formatPrice(pay.amount) }}
                      <div v-if="pay.amount_bs > 0" class="text-sm text-gray-500">
                        BsD. {{ formatPrice(pay.amount_bs) }}
                      </div>
                    </span>
                  </div>
                  <div class="mt-2 text-sm text-gray-500">
                    <div>{{ pay.created_at }}</div>
                    <div v-if="pay.reference">Ref: {{ pay.reference }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import LayoutFullVue from '../../Shared/LayoutFull.vue'
import TextInput from '../../Shared/TextInput.vue'
import { Head, Link } from '@inertiajs/inertia-vue3'
import PaymentBox from '../../Shared/PaymentBox.vue'

export default {
  layout: LayoutFullVue,
  
  components: {
    TextInput,
    Head,
    PaymentBox,
    Link
  },

  props: {
    order: {
      type: Object,
      required: true
    },
    role: {
      type: String,
      required: true
    },
    payment_methods: {
      type: Object,
      required: true  
    },
    change: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      form: this.$inertia.form({
        method: 'efectivo',
        amount: this.order.total - this.order.balance,
        order_id: this.order.id,
        quick: true,
      })
    }
  },

  methods: {
    getOrderState(state) {
      const states = {
        cancel: 'ANULADA',
        request: 'PENDIENTE POR PAGO',
        finish: 'FINALIZADA', 
        pay: 'PAGADA',
        delivery: 'EN ESPERA DE DESPACHO'
      }
      return states[state] || state
    },

    formatPrice(value) {
      return Number(value).toFixed(2)
    },

    cancelOrder() {
      if (confirm('¿Está seguro que desea anular esta orden?')) {
        window.location.href = `/orders/${this.order.id}/cancel`
      }
    },

    print(exchange) {
      window.open(
        `/orders/${this.order.id}/report-thermical/${exchange}`,
        `Venta #${this.order.number_order}`,
        'width=400,height=500,toolbar=no'
      )
    }
  }
}
</script>
