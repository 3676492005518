<template>
    <div>

        <Head title="Detalles del Proveedor" />

        <!-- Encabezado -->
        <div class="flex items-center mb-8">
            <button class="mr-4 p-2.5 bg-teal-800 hover:bg-teal-900 text-white rounded-lg transition-colors"
                @click="back" title="Volver">
                <i class="fa fa-arrow-left"></i>
            </button>
            <h1 class="text-3xl font-bold flex items-center">
                <Link class="text-blue-600 hover:text-blue-700 transition-colors" href="/providers">
                Proveedores
                </Link>
                <span class="mx-2 text-gray-400">/</span>
                <span>{{ provider.company_name }}</span>
            </h1>
        </div>

        <!-- Información del Proveedor -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
            <div class="bg-white rounded-lg shadow-sm p-6">
                <h2 class="text-xl font-bold mb-4">Información General</h2>
                <div class="space-y-3">
                    <div>
                        <span class="font-semibold">Nombre:</span> {{ provider.name }}
                    </div>
                    <div>
                        <span class="font-semibold">RIF:</span> {{ provider.tax_id }}
                    </div>
                    <div>
                        <span class="font-semibold">Email:</span> {{ provider.email }}
                    </div>
                    <div>
                        <span class="font-semibold">Teléfono:</span> {{ provider.phone }}
                    </div>
                    <div>
                        <span class="font-semibold">Dirección:</span> {{ provider.address }}
                    </div>
                    <div>
                        <Link 
                            class="text-blue-600 hover:text-blue-700 transition-colors"
                            :href="`/providers/${provider.id}/edit`"
                        >
                            <i class="fa fa-edit mr-1"></i>
                            Editar proveedor
                        </Link>
                    </div>
                </div>
            </div>

            <div class="bg-white rounded-lg shadow-sm p-6">
                <h2 class="text-xl font-bold mb-4">Balance General</h2>
                <div class="text-2xl font-bold mb-4">
                    <span class="text-gray-600">Total Adeudado:</span>
                    <span :class="{ 'text-red-600': provider.balance > 1, 'text-green-600': provider.balance == 0 }">
                        ${{ provider.balance }}
                    </span>
                </div>
                <div class="space-y-2">
                    <div>
                        <span class="font-semibold">Última Compra:</span>
                        {{ lastPurchaseDate ? formatDate(lastPurchaseDate) : 'Sin compras' }}
                    </div>
                </div>
            </div>
        </div>

        <!-- Lista de Facturas -->
        <div class="bg-white rounded-lg shadow-sm p-6">
            <h2 class="text-xl font-bold mb-4">Facturas Pendientes</h2>

            <div class="overflow-x-auto">
                <table class="w-full">
                    <thead>
                        <tr class="bg-gray-50">
                            <th class="px-4 py-3 text-left">N° Factura</th>
                            <th class="px-4 py-3 text-left">Fecha</th>
                            <th class="px-4 py-3 text-right">Monto</th>
                            <th class="px-4 py-3 text-left">Estado</th>
                            <th class="px-4 py-3 text-center">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="invoice in pendingInvoices" :key="invoice.id" class="border-t">
                            <td class="px-4 py-3">{{ invoice.number_shopping || invoice.id }}</td>
                            <td class="px-4 py-3">{{ formatDate(invoice.date) }}</td>
                            <td class="px-4 py-3 text-right">${{ invoice.total }}</td>
                            <td class="px-4 py-3">
                                <span :class="{
                                    'px-2 py-1 rounded text-sm font-semibold': true,
                                    'bg-yellow-100 text-yellow-800': invoice.status === 'pending',
                                    'bg-green-100 text-green-800': invoice.status === 'completed' || invoice.status === 'paid',
                                    'bg-red-100 text-red-800': invoice.status === 'cancelled'
                                }">
                                    {{ {
                                        completed: 'Completada',
                                        paid: 'Pagada', 
                                        cancelled: 'Cancelada',
                                        pending: 'Pendiente'
                                    }[invoice.status] }}
                                </span>
                            </td>
                            <td class="px-4 py-3 text-center">
                                <button v-if="!invoice.paid" @click="markAsPaid(invoice.id)"
                                    class="bg-green-600 hover:bg-green-700 text-white px-3 py-1 rounded-lg text-sm transition-colors">
                                    Marcar como Pagada
                                </button>
                                <Link :href="`/shoppings/${invoice.id}/report`"
                                    class="ml-2 text-blue-600 hover:text-blue-800" target="_blank">
                                <i class="fas fa-file-pdf"></i>
                                </Link>
                            </td>
                        </tr>
                        <tr v-if="pendingInvoices.length === 0">
                            <td colspan="5" class="px-4 py-3 text-center text-gray-500">
                                No hay facturas pendientes
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'

export default {
    name: 'ProvidersShow',

    components: {
        Head,
        Link
    },

    layout: Layout,

    props: {
        provider: {
            type: Object,
            required: true
        },
        shoppings: {
            type: Array,
            required: true
        }
    },

    computed: {
        pendingInvoices() {
            return this.shoppings.filter(shopping => !shopping.paid)
        },

        totalDebt() {
            return this.pendingInvoices.reduce((sum, invoice) => sum + invoice.total, 0)
        },

        lastPurchaseDate() {
            if (this.shoppings.length === 0) return null
            return this.shoppings[0].date
        }
    },

    methods: {
        back() {
            this.$inertia.visit('/providers')
        },
        formatDate(date) {
            return new Date(date).toLocaleDateString('es-ES', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            })
        },

        async markAsPaid(invoiceId) {
            try {
                await this.$inertia.get(`/shoppings/${invoiceId}/paid`)
            } catch (error) {
                console.error('Error al marcar como pagada:', error)
            }
        }
    }
}
</script>
