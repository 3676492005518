<template>
  <div>
    <!-- Encabezado con título y botones de acción -->
    <Head :title="`Compra #${shopping.id} ${shopping.status === 'cancelled' ? '| COMPRA ANULADA' : ''}`" />

    <div class="flex justify-between items-center mb-6">
      <div class="flex items-center">
        <button 
          class="mr-4 p-3 text-white bg-teal-800 hover:bg-teal-900 rounded transition-colors" 
          @click="back()"
          title="Volver atrás"
        >
          <i class="fa fa-arrow-left"></i>
        </button>
        
        <h1 class="text-3xl font-bold">
          Compra #{{ shopping.id }}
          <span v-if="shopping.status === 'cancelled'" class="text-red-500 ml-2">
            COMPRA ANULADA
          </span>
          <span v-if="shopping.status === 'paid'" class="text-blue-500 ml-2">
            COMPRA PAGADA
          </span>
        </h1>
      </div>

      <!-- Botones de impresión -->
      <div class="flex gap-2">
        <a 
          target="_blank" 
          :href="`/shoppings/${shopping.id}/report`"
          class="btn-blue p-3 rounded hover:opacity-90 transition-opacity"
        >
          <i class="fa fa-print text-white mr-2" />
          Imprimir Compra
        </a>
      </div>
    </div>

    <!-- Información del proveedor y detalles de la compra -->
    <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mb-6 p-6 bg-white rounded-lg shadow">
      <div class="space-y-3">
        <div class="grid grid-cols-2 gap-2">
          <div class="font-semibold">Proveedor:</div>
          <div>{{ shopping.provider?.company_name ?? shopping.provider?.name }}</div>

          <div class="font-semibold">RIF/Cédula:</div>
          <div>{{ shopping.provider?.identification }}</div>

          <div class="font-semibold">Email:</div>
          <div>{{ shopping.provider?.email }}</div>

          <div class="font-semibold">Teléfono:</div>
          <div>{{ shopping.provider?.phone }}</div>
        </div>

        <div class="border-t pt-3 mt-3">
          <div class="grid grid-cols-2 gap-2">
            <div class="font-semibold">Factura:</div>
            <div>{{ shopping.invoice_number }}</div>

            <div class="font-semibold">Fecha:</div>
            <div>{{ formatDate(shopping.date) }}</div>

            <div class="font-semibold">Vencimiento:</div>
            <div>{{ formatDate(shopping.due_date) }}</div>

            <div class="font-semibold">Total:</div>
            <div>${{ shopping.total }}</div>

            <div class="font-semibold">Estado:</div>
            <div>
              <span 
                class="px-3 py-1 rounded-full text-sm"
                :class="{
                  'bg-green-100 text-green-800': shopping.status === 'completed',
                  'bg-yellow-100 text-yellow-800': shopping.status === 'pending',
                  'bg-red-100 text-red-800': shopping.status === 'cancelled',
                  'bg-blue-100 text-blue-800': shopping.status === 'paid'
                }"
              >
                {{ shopping.status === 'pending' ? 'Pendiente' : shopping.status === 'cancelled' ? 'Anulada' : shopping.status === 'paid' ? 'Pagada' : shopping.status }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Sección de productos -->
    <div class="p-6 bg-white rounded-lg shadow">
      <h2 class="text-xl font-bold mb-6">Productos</h2>

      <div class="overflow-x-auto">
        <table class="w-full">
          <thead>
            <tr>
              <th class="py-4 px-6 text-left">Producto</th>
              <th class="py-4 px-6 text-left">Código</th>
              <th class="py-4 px-6 text-left">Cantidad</th>
              <th class="py-4 px-6 text-left">Precio</th>
              <th class="py-4 px-6 text-left">Total</th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="product in shopping.products"
              :key="product.id"
              class="border-t hover:bg-gray-50 transition-colors"
            >
              <td class="py-3 px-6">{{ product.product.name }}</td>
              <td class="py-3 px-6">{{ product.product.code }}</td>
              <td class="py-3 px-6">{{ product.quantity }}</td>
              <td class="py-3 px-6">${{ product.price }}</td>
              <td class="py-3 px-6">${{ (product.price * product.quantity).toFixed(2) }}</td>
            </tr>
          </tbody>

          <tfoot>
            <tr class="bg-gray-50 font-semibold">
              <td colspan="4" class="py-3 px-6 text-right">Total:</td>
              <td class="py-3 px-6">${{ shopping.total }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>


    <!-- Botón de anular compra -->
    <div 
      class="mt-6 p-6 bg-white rounded-lg shadow text-right space-x-4"
    >
      <button
        v-if="shopping.status !== 'paid'"
        @click="markAsPaid"
        class="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
      >
        Marcar como Pagada
      </button>
      <button
        v-if="shopping.status !== 'cancelled'"
        @click="cancelShopping"
        class="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
      >
        Anular Compra
      </button>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'

export default {
  components: {
    Head,
    Link
  },

  layout: Layout,

  props: {
    shopping: {
      type: Object,
      required: true
    }
  },

  methods: {
    back() {
      window.history.back()
    },

    formatDate(date) {
      return new Date(date).toLocaleDateString('es-ES', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    },

    cancelShopping() {
      if (confirm('¿Está seguro que desea anular esta compra?')) {
        this.$inertia.get(`/shoppings/${this.shopping.id}/cancel`)
      }
    },

    markAsPaid() {
      this.$inertia.get(`/shoppings/${this.shopping.id}/paid`)
    }
  }
}
</script>
