<template>
  <div>
    <Head title="Categorías de Productos" />
    
    <!-- Header -->
    <div class="flex justify-between items-center mb-8">
      <div class="flex items-center gap-4">
        <button 
          class="p-3 text-white bg-teal-700 hover:bg-teal-800 rounded-lg transition-colors"
          title="Volver atrás"
          @click="back()"
        >
          <i class="fa fa-arrow-left"></i>
        </button>
        <h1 class="text-3xl font-bold">Categorías de Productos</h1>
      </div>
    </div>

    <!-- Actions -->
    <div class="flex flex-wrap items-center justify-between gap-4 mb-6">
      <Link 
        class="btn-blue flex items-center gap-2" 
        href="/product-categories/create"
      >
        <i class="fa fa-plus"></i>
        <span>Crear Categoría</span>
      </Link>

      <div class="flex flex-wrap items-center gap-3">
        <button 
          class="btn-blue flex items-center gap-2"
          :class="{'bg-blue-700': searchForm}"
          title="Buscar categorías"
          @click="toggleSearch"
        >
          <i class="fa fa-search"></i>
          <span class="hidden sm:inline">Buscar</span>
        </button>
      </div>
    </div>

    <!-- Search -->
    <div 
      v-show="searchForm"
      class="p-4 mb-6 bg-white rounded-lg shadow-sm border border-gray-200"
    >
      <text-input
        v-model="form.search"
        label="Buscar categorías"
        placeholder="Ingrese el nombre de la categoría..."
        class="w-full lg:w-1/2"
      />
    </div>

    <!-- Categories Table -->
    <div class="bg-white rounded-lg shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr class="text-left bg-gray-50">
            <th class="py-4 px-6 font-semibold">Nombre</th>
            <th class="py-4 px-6 font-semibold">Productos</th>
            <th class="py-4 px-6 font-semibold">Fecha creación</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="category in productCategories.data" 
            :key="category.id"
            class="border-t border-gray-100 hover:bg-gray-50 transition-colors"
          >
            <td class="py-4 px-6">
              <Link 
                :href="`/product-categories/${category.id}/edit`"
                class="text-gray-600 hover:text-blue-600"
              >
                {{ category.name }}
              </Link>
            </td>
            <td class="py-4 px-6 text-gray-600">
              {{ category.products_count || 0 }}
            </td>
            <td class="py-4 px-6 text-gray-600">
              {{ new Date(category.created_at).toLocaleDateString() }}
            </td>
          </tr>

          <tr v-if="!productCategories.data.length">
            <td 
              class="px-6 py-8 text-center text-gray-500 border-t" 
              colspan="3"
            >
              No hay categorías disponibles
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <pagination 
      v-if="productCategories.links"
      :links="productCategories.links" 
      class="mt-6"
    />
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import Pagination from '@/Shared/Pagination.vue'
import TextInput from '@/Shared/TextInput.vue'
import throttle from 'lodash/throttle'
import pickBy from 'lodash/pickBy'

export default {
  name: 'ProductCategoriesIndex',
  
  components: {
    Head,
    Link,
    Pagination,
    TextInput
  },

  layout: Layout,

  props: {
    filters: Object,
    productCategories: Object
  },

  data() {
    return {
      searchForm: false,
      form: {
        search: this.filters.search || ''
      }
    }
  },

  watch: {
    form: {
      deep: true,
      handler: throttle(function() {
        this.$inertia.get(
          '/product-categories',
          pickBy(this.form),
          { preserveState: true }
        )
      }, 300)
    }
  },

  methods: {
    back() {
      window.history.back()
    },

    toggleSearch() {
      this.searchForm = !this.searchForm
      if(this.searchForm) {
        this.$nextTick(() => {
          this.$refs.searchInput?.focus()
        })
      }
    }
  }
}
</script>
