<template>
  <div>
    <Head :title="`Editar cuenta`" />
    
    <!-- Header -->
    <div class="flex items-center gap-4 mb-8">
      <button 
        class="p-3 text-white bg-indigo-700 hover:bg-indigo-800 rounded-lg transition-colors"
        title="Volver atrás"
        @click="$inertia.visit('/')"
      >
        <i class="fa fa-arrow-left"></i>
      </button>
      <h1 class="text-3xl font-bold">
        <Link 
          class="text-indigo-600 hover:text-indigo-700 transition-colors" 
          href="/"
        >
          Cuenta
        </Link>
        <span class="text-gray-400 font-medium mx-2">/</span>
        <span>{{ form.name }}</span>
        <img v-if="logo" :src="logo" alt="Logo" class="h-8 ml-2 inline-block">
      </h1>
    </div>

    <!-- Formulario -->
    <div class="max-w-3xl bg-white rounded-lg shadow-sm border border-gray-200">
      <form @submit.prevent="update">
        <div class="p-8">
          <h2 class="text-lg font-bold mb-4">Información de la cuenta</h2>
          
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <!-- Datos básicos -->
            <text-input
              v-model="form.name"
              :error="form.errors.name"
              label="Nombre *"
              placeholder="Ingrese el nombre"
            />
            
            <text-input
              v-model="form.identification" 
              :error="form.errors.identification"
              label="Identificación *"
              placeholder="Ingrese la identificación"
            />

            <text-input
              v-model="form.email"
              :error="form.errors.email"
              type="email"
              label="Email *" 
              placeholder="correo@ejemplo.com"
            />

            <text-input
              v-model="form.phone"
              :error="form.errors.phone"
              label="Teléfono"
              placeholder="Ingrese el teléfono"
            />

            <text-input
              v-model="form.reasonName"
              :error="form.errors.reasonName"
              label="Razón Social"
              placeholder="Ingrese la razón social"
            />

            <text-input
              v-model="form.address"
              :error="form.errors.address"
              label="Dirección"
              placeholder="Ingrese la dirección"
            />

            <!-- Personalización -->
            <text-input
              v-model="form.sidebarColor"
              :error="form.errors.sidebarColor"
              type="color"
              label="Color del menú lateral"
            />

            <text-input
              v-model="form.textSidebarColor"
              :error="form.errors.textSidebarColor"
              type="color" 
              label="Color del texto del menú"
            />

            <text-input
              v-model="form.textHoverSidebarColor"
              :error="form.errors.textHoverSidebarColor"
              type="color"
              label="Color del texto al pasar el mouse"
            />

            <file-input
              v-model="form.logo"
              :error="form.errors.logo"
              accept="image/*"
              label="Logo de la empresa"
            />
          </div>
        </div>

        <div class="flex items-center justify-end gap-4 px-8 py-4 bg-gray-50 border-t border-gray-100">
          <button
            type="button"
            class="btn-white"
            @click="$inertia.visit('/')"
          >
            Cancelar
          </button>
          <loading-button
            :loading="form.processing"
            class="btn-blue"
            type="submit"
          >
            <i class="fa fa-save mr-2"></i>
            Guardar cambios
          </loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import FileInput from '@/Shared/FileInput'
import LoadingButton from '@/Shared/LoadingButton'

export default {
  layout: Layout,
  
  components: {
    Head,
    Link,
    LoadingButton,
    TextInput,
    FileInput
  },

  props: {
    account: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      logo: this.account.logo,
      form: this.$inertia.form({
        _method: 'put',
        name: this.account.name,
        identification: this.account.identification,
        email: this.account.email,
        phone: this.account.phone,
        reasonName: this.account.reasonName,
        address: this.account.address,
        sidebarColor: this.account.sidebarColor,
        textSidebarColor: this.account.textSidebarColor,
        textHoverSidebarColor: this.account.textHoverSidebarColor,
        logo: null
      })
    }
  },

  methods: {
    update() {
      this.form.post(`/accounts/${this.account.id}`, {
        onSuccess: () => {
          this.$notify({
            type: 'success',
            text: 'Cuenta actualizada correctamente'
          })
        }
      })
    }
  }
}
</script>
