<template>
  <div>
    <Head title="Proveedores" />

    <!-- Header y Barra de herramientas -->
    <app-toolbar 
      name="Proveedor" 
      namePlural="Proveedores" 
      createHref="/providers/create" 
      :exportButtons="true"
    >
      <!-- Filtros de búsqueda -->
      <template v-slot:search>
        <search-filter 
          v-model="form.search" 
          class="mr-4 w-full max-w-md" 
          @reset="reset"
        >
          <label class="block text-gray-700 mb-1">Estado:</label>
          <select 
            v-model="form.trashed" 
            class="form-select w-full rounded-lg border-gray-300 focus:border-blue-500 focus:ring-blue-500"
          >
            <option :value="null">Todos</option>
            <option value="with">Con eliminados</option>
            <option value="only">Solo eliminados</option>
          </select>
        </search-filter>
      </template>

      <!-- Botones de exportación -->
      <template v-slot:exportArea>
        <div class="flex items-center gap-2">
          <a 
            target="_blank" 
            href="/providers/export/excel"
            class="p-2.5 bg-green-600 hover:bg-green-700 rounded-lg transition-colors"
            title="Exportar a Excel"
          >
            <i class="fa fa-file text-white" />
          </a>
          <a 
            target="_blank" 
            href="/providers/export/pdf"
            class="p-2.5 bg-red-700 hover:bg-red-800 rounded-lg transition-colors"
            title="Exportar a PDF"
          >
            <i class="fa fa-print text-white" />
          </a>
        </div>
      </template>
    </app-toolbar>

    <!-- Tabla de proveedores -->
    <div class="bg-white rounded-lg shadow-sm overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr class="text-left bg-gray-50">
            <th class="py-4 px-6 font-semibold" colspan="2">Nombre</th>
            <th class="py-4 px-6 font-semibold">Empresa</th>
            <th class="py-4 px-6 font-semibold">RIF</th>
            <th class="py-4 px-6 font-semibold">Correo electrónico</th>
            <th class="py-4 px-6 font-semibold">Teléfono</th>
            <th class="py-4 px-6 font-semibold">Ciudad</th>
            <th class="py-4 px-6 font-semibold">País</th>
            <th class="py-4 px-6 font-semibold">Sitio web</th>
            <th class="py-4 px-6 font-semibold" colspan="2">Dirección</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="provider in providers.data" 
            :key="provider.id" 
            class="hover:bg-gray-50 focus-within:bg-gray-50"
          >
            <td class="border-t">
              <Link 
                class="flex items-center px-6 py-4 hover:text-blue-600 focus:text-blue-600 transition-colors" 
                :href="`/providers/${provider.id}`"
              >
                {{ provider.name }}
                <icon 
                  v-if="provider.deleted_at" 
                  name="trash" 
                  class="ml-2 w-4 h-4 text-gray-400"
                  title="Proveedor eliminado"
                />
              </Link>
            </td>
            <td class="border-t">
              <Link 
                class="block px-6 py-4 hover:text-blue-600"
                :href="`/providers/${provider.id}`"
              >
                {{ provider.company_name }}
              </Link>
            </td>
            <td class="border-t">
              <Link 
                class="block px-6 py-4 hover:text-blue-600"
                :href="`/providers/${provider.id}`"
              >
                {{ provider.tax_id }}
              </Link>
            </td>
            <td class="border-t">
              <Link 
                class="block px-6 py-4 hover:text-blue-600"
                :href="`/providers/${provider.id}`"
              >
                {{ provider.email }}
              </Link>
            </td>
            <td class="border-t">
              <Link 
                class="block px-6 py-4 hover:text-blue-600"
                :href="`/providers/${provider.id}`"
              >
                {{ provider.phone }}
              </Link>
            </td>
            <td class="border-t">
              <Link 
                class="block px-6 py-4 hover:text-blue-600"
                :href="`/providers/${provider.id}`"
              >
                {{ provider.city }}
              </Link>
            </td>
            <td class="border-t">
              <Link 
                class="block px-6 py-4 hover:text-blue-600"
                :href="`/providers/${provider.id}`"
              >
                {{ provider.country }}
              </Link>
            </td>
            <td class="border-t">
              <Link 
                class="block px-6 py-4 hover:text-blue-600"
                :href="`/providers/${provider.id}`"
              >
                {{ provider.website }}
              </Link>
            </td>
            <td class="border-t">
              <Link 
                class="block px-6 py-4 hover:text-blue-600"
                :href="`/providers/${provider.id}`"
              >
                {{ provider.address }}
              </Link>
            </td>
            <td class="w-px border-t">
              <Link 
                class="block p-4 hover:text-blue-600"
                :href="`/providers/${provider.id}`"
              >
                <icon 
                  name="cheveron-right" 
                  class="w-6 h-6 text-gray-400"
                />
              </Link>
            </td>
          </tr>

          <tr v-if="providers.data.length === 0">
            <td 
              class="px-6 py-8 text-center text-gray-500 border-t" 
              colspan="10"
            >
              No hay proveedores disponibles
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <pagination 
      v-if="providers.links" 
      :links="providers.links" 
      class="mt-6"
    />
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import pickBy from 'lodash/pickBy'
import Layout from '@/Shared/Layout'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import SearchFilter from '@/Shared/SearchFilter'
import AppToolbar from '@/Shared/AppToolbar.vue'

export default {
  name: 'ProvidersIndex',

  components: {
    Head,
    Icon,
    Link,
    Pagination,
    SearchFilter,
    AppToolbar,
  },

  layout: Layout,

  props: {
    filters: Object,
    providers: Object,
  },

  data() {
    return {
      form: {
        search: this.filters.search,
        trashed: this.filters.trashed,
      }
    }
  },

  watch: {
    form: {
      deep: true,
      handler: throttle(function() {
        this.$inertia.get('/providers', pickBy(this.form), { 
          preserveState: true 
        })
      }, 300),
    },
  },

  methods: {
    back() {
      window.history.back()
    },

    reset() {
      this.form = mapValues(this.form, () => null)
    }
  },
}
</script>
