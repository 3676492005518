<template>
  <div>
    <Head title="Crear gasto" />
    
    <!-- Header -->
    <div class="flex items-center gap-4 mb-8">
      <button 
        class="p-3 text-white bg-teal-700 hover:bg-teal-800 rounded-lg transition-colors"
        title="Volver atrás"
        @click="back()"
      >
        <i class="fa fa-arrow-left"></i>
      </button>
      <h1 class="text-3xl font-bold">
        <Link 
          class="text-teal-600 hover:text-teal-700 transition-colors" 
          href="/spents"
        >
          Gastos
        </Link>
        <span class="text-gray-400 font-medium mx-2">/</span>
        <span>Crear</span>
      </h1>
    </div>

    <!-- Form -->
    <div class="max-w-3xl bg-white rounded-lg shadow-sm border border-gray-200">
      <form @submit.prevent="store">
        <div class="p-8">
          <h2 class="text-lg font-bold mb-4">Información del gasto</h2>
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <text-input
              v-model="form.name"
              :error="form.errors.name"
              label="Nombre *"
              placeholder="Ingrese el nombre del gasto"
            />
            <text-input
              v-model="form.date"
              :error="form.errors.date"
              type="date"
              label="Fecha *"
            />
            <text-input
              v-model="form.amount"
              :error="form.errors.amount"
              type="number"
              min="0"
              step="0.01"
              label="Monto *"
              placeholder="0.00"
            />
            <select-input
              v-model="form.spent_category_id"
              :error="form.errors.spent_category_id"
              label="Categoría *"
            >
              <option value="">Seleccionar categoría</option>
              <option
                v-for="category in categories"
                :key="category.id"
                :value="category.id"
              >
                {{ category.name }}
              </option>
            </select-input>
            <select-input
              v-model="form.method"
              :error="form.errors.method"
              label="Método de pago *"
            >
              <option value="">Seleccionar método</option>
              <option value="efectivo">Efectivo</option>
              <option value="transferencia">Transferencia</option>
              <option value="pago-movil">Pago Móvil</option>
              <option value="zelle">Zelle</option>
            </select-input>
          </div>
        </div>

        <!-- Submit -->
        <div class="flex items-center justify-end px-8 py-4 bg-gray-50 border-t border-gray-100">
          <loading-button
            :loading="form.processing"
            class="px-6 py-3 bg-teal-600 hover:bg-teal-700 text-white font-medium rounded-lg transition-colors"
            type="submit"
          >
            <i class="fa fa-save mr-2"></i>
            Crear gasto
          </loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput'

export default {
  name: 'SpentCreate',

  components: {
    Head,
    Link,
    LoadingButton,
    TextInput,
    SelectInput
  },

  props: {
    categories: {
      type: Object,
      required: true
    }
  },

  layout: Layout,

  remember: 'form',

  data() {
    return {
      form: this.$inertia.form({
        name: '',
        amount: '',
        spent_category_id: '',
        date: '',
        method: ''
      })
    }
  },

  methods: {
    back() {
      window.history.back()
    },

    store() {
      this.form.post('/spents')
    }
  }
}
</script>