<template>
  <div>
    <Head title="Compras" />
    
    <!-- Header -->
    <div class="flex justify-between items-center mb-8">
      <div class="flex items-center gap-4">
        <button 
          class="p-3 text-white bg-teal-700 hover:bg-teal-800 rounded-lg transition-colors"
          title="Volver atrás"
          @click="back()"
        >
          <i class="fa fa-arrow-left"></i>
        </button>
        <h1 class="text-3xl font-bold">Compras</h1>
      </div>
    </div>

    <!-- Actions -->
    <div class="flex flex-wrap items-center justify-between gap-4 mb-6">
      <Link 
        class="btn-blue flex items-center gap-2" 
        href="/shoppings/create"
      >
        <i class="fa fa-plus"></i>
        <span>Nueva Compra</span>
      </Link>

      <div class="flex flex-wrap items-center gap-3">
        <button 
          class="btn-blue flex items-center gap-2"
          :class="{'bg-blue-700': searchForm}"
          title="Buscar compras"
          @click="toggleSearch"
        >
          <i class="fa fa-search"></i>
          <span class="hidden sm:inline">Buscar</span>
        </button>
      </div>
    </div>

    <!-- Search -->
    <div 
      v-show="searchForm"
      class="p-4 mb-6 bg-white rounded-lg shadow-sm border border-gray-200"
    >
      <text-input
        v-model="form.search"
        label="Buscar compras"
        placeholder="Ingrese el proveedor o número de factura..."
      />
    </div>

    <!-- Table -->
    <div class="bg-white rounded-lg shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr class="text-left bg-gray-50">
            <th class="py-4 px-6 font-semibold">#</th>
            <th class="py-4 px-6 font-semibold">Proveedor</th>
            <th class="py-4 px-6 font-semibold">Factura</th>
            <th class="py-4 px-6 font-semibold">Fecha</th>
            <th class="py-4 px-6 font-semibold">Vencimiento</th>
            <th class="py-4 px-6 font-semibold">Total</th>
            <th class="py-4 px-6 font-semibold">Estado</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="shopping in shopping.data" 
            :key="shopping.id"
            class="border-t border-gray-100 hover:bg-gray-50 transition-colors"
          >
            <td class="py-4 px-6">
              <Link 
                :href="`/shoppings/${shopping.id}`"
                class="text-gray-600 hover:text-blue-600"
              >
                {{ shopping.id }}
              </Link>
            </td>
            <td class="py-4 px-6">
              <Link 
                :href="`/shoppings/${shopping.id}`"
                class="text-gray-600 hover:text-blue-600"
              >
                {{ shopping.provider?.company_name ?? shopping.provider?.name }}
              </Link>
            </td>
            <td class="py-4 px-6">
              <Link 
                :href="`/shoppings/${shopping.id}`"
                class="text-gray-600 hover:text-blue-600"
              >
                {{ shopping.invoice_number }}
              </Link>
            </td>
            <td class="py-4 px-6">
              <Link 
                :href="`/shoppings/${shopping.id}`"
                class="text-gray-600 hover:text-blue-600"
              >
                {{ new Date(shopping.date).toLocaleDateString() }}
              </Link>
            </td>
            <td class="py-4 px-6">
              <Link 
                :href="`/shoppings/${shopping.id}`"
                class="text-gray-600 hover:text-blue-600"
              >
                {{ new Date(shopping.due_date).toLocaleDateString() }}
              </Link>
            </td>
            <td class="py-4 px-6">
              <Link 
                :href="`/shoppings/${shopping.id}`"
                class="text-gray-600 hover:text-blue-600"
              >
                ${{ shopping.total }}
              </Link>
            </td>
            <td class="py-4 px-6">
              <Link 
                :href="`/shoppings/${shopping.id}`"
                class="text-gray-600 hover:text-blue-600"
              >
                <span 
                  class="px-3 py-1 rounded-full text-sm"
                  :class="{
                    'bg-green-100 text-green-800': shopping.status === 'completed',
                    'bg-yellow-100 text-yellow-800': shopping.status === 'pending',
                    'bg-red-100 text-red-800': shopping.status === 'cancelled',
                    'bg-blue-100 text-blue-800': shopping.status === 'paid'
                  }"
                >
                  {{ shopping.status === 'pending' ? 'Pendiente' : 
                     shopping.status === 'cancelled' ? 'Anulada' : 
                     shopping.status === 'paid' ? 'Pagada' : 
                     shopping.status }}
                </span>
              </Link>
            </td>
          </tr>
          <tr v-if="!shopping.data.length">
            <td 
              class="px-6 py-8 text-center text-gray-500 border-t" 
              colspan="7"
            >
              No hay compras disponibles
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <pagination 
      v-if="shopping.meta?.links"
      :links="shopping.meta.links" 
      class="mt-6"
    />
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import Pagination from '@/Shared/Pagination'
import TextInput from '@/Shared/TextInput'

export default {
  components: {
    Head,
    Link,
    Pagination,
    TextInput
  },

  layout: Layout,

  props: {
    shopping: Object,
    user: Object
  },

  data() {
    return {
      searchForm: false,
      form: this.$inertia.form({
        search: '',
      }),
    }
  },

  methods: {
    back() {
      window.history.back()
    },

    toggleSearch() {
      this.searchForm = !this.searchForm
    }
  } 
}
</script>
