<template>
  <div>
    <Head title="Inventarios" />
    
    <!-- Header y Barra de herramientas -->
    <app-toolbar
      name="Inventario" 
      namePlural="Inventarios"
      createHref="/inventories/create"
      :exportButtons="true"
    >
      <!-- Filtros de búsqueda -->
      <template v-slot:search>
        <search-filter 
          v-model="form.search" 
          class="mr-4 w-full max-w-md"
          @reset="reset"
        >
          <label class="block text-gray-700 mb-1">Estado:</label>
          <select 
            v-model="form.trashed"
            class="form-select w-full rounded-lg border-gray-300 focus:border-blue-500 focus:ring-blue-500"
          >
            <option :value="null">Todos</option>
            <option value="with">Con eliminados</option>
            <option value="only">Solo eliminados</option>
          </select>
        </search-filter>
      </template>


    </app-toolbar>

    <!-- Tabla de inventarios -->
    <div class="bg-white rounded-lg shadow-sm overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr class="text-left bg-gray-50">
            <th class="py-4 px-6 font-semibold">Fecha</th>
            <th class="py-4 px-6 font-semibold">Usuario</th>
            <th class="py-4 px-6 font-semibold">Cantidad agregada</th>
            <th class="py-4 px-6 font-semibold">Estado</th>
            <th class="py-4 px-6 font-semibold"></th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="inventory in inventories" 
            :key="inventory.id"
            class="hover:bg-gray-50 focus-within:bg-gray-50"
          >
            <td class="border-t">
              <Link 
                :href="`/inventories/${inventory.id}`"
                class="flex items-center px-6 py-4 hover:text-blue-600 focus:text-blue-600 transition-colors"
              >
                {{ getDate(inventory.created_at) }}
              </Link>
            </td>
            <td class="border-t">
              <Link 
                :href="`/inventories/${inventory.id}`"
                class="block px-6 py-4 hover:text-blue-600"
              >
                {{ inventory.user.first_name }} {{ inventory.user.last_name }}
              </Link>
            </td>
            <td class="border-t">
              <Link 
                :href="`/inventories/${inventory.id}`"
                class="block px-6 py-4 hover:text-blue-600"
              >
                {{ inventory.quantity }}
              </Link>
            </td>
            <td class="border-t">
              <Link 
                :href="`/inventories/${inventory.id}`"
                class="block px-6 py-4 hover:text-blue-600"
              >
                <span :class="{
                  'px-2 py-1 rounded text-sm font-medium': true,
                  'bg-yellow-100 text-yellow-800': inventory.status === 'request',
                  'bg-green-100 text-green-800': inventory.status === 'process'
                }">
                  {{ inventory.status === 'request' ? 'Solicitud' : 'Procesado' }}
                </span>
              </Link>
            </td>
            <td class="w-px border-t">
              <Link 
                :href="`/inventories/${inventory.id}`"
                class="block p-4 hover:text-blue-600"
              >
                <icon 
                  name="cheveron-right"
                  class="w-6 h-6 text-gray-400"
                />
              </Link>
            </td>
          </tr>

          <tr v-if="!inventories || inventories.length === 0">
            <td 
              class="px-6 py-8 text-center text-gray-500 border-t"
              colspan="5"
            >
              No hay registros de inventario disponibles
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <pagination 
      v-if="inventories.links"
      :links="inventories.links"
      class="mt-6"
    />
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import pickBy from 'lodash/pickBy'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import Pagination from '@/Shared/Pagination'
import SearchFilter from '@/Shared/SearchFilter'
import AppToolbar from '../../Shared/AppToolbar.vue'

export default {
  name: 'InventoriesIndex',

  components: {
    Head,
    Icon,
    Link,
    Pagination,
    SearchFilter,
    AppToolbar,
  },

  layout: Layout,

  props: {
    inventories: Object,
    filters: Object,
    user: Object,
  },

  data() {
    return {
      form: {
        search: this.filters?.search,
        trashed: this.filters?.trashed,
      }
    }
  },

  watch: {
    form: {
      deep: true,
      handler: throttle(function() {
        this.$inertia.get('/inventories', pickBy(this.form), {
          preserveState: true
        })
      }, 300),
    },
  },

  methods: {
    getDate(date) {
      return new Date(date).toLocaleString('es-VE', { 
        hour12: false,
        dateStyle: 'medium',
        timeStyle: 'short'
      })
    },

    reset() {
      this.form = mapValues(this.form, () => null)
    }
  }
}
</script>
