<template>
  <div>
    <Head title="Panel de Control" />
    <h1 class="mb-8 text-3xl font-bold">Panel de Control</h1>

    <!-- Estadísticas principales para administradores -->
    <div v-if="role === 'admin'" class="grid grid-cols-1 gap-6 md:grid-cols-4 mb-12">
      <box-dashboard 
        v-if="['financial', 'admin'].includes(role)" 
        title="Cuentas por Cobrar"
        :value="balanceTotal ? formatCurrency(balanceTotal) : '$ 0'"
      />
      <box-dashboard 
        title="Ventas del Mes"
        :value="orderMonthTotal ? formatCurrency(orderMonthTotal) : '$ 0'"
      />
      <box-dashboard 
        title="Gastos del Mes"
        :value="spentTotal ? formatCurrency(spentTotal) : '$ 0'"
      />
      <box-dashboard 
        title="Utilidad"
        :value="orderMonthTotal && spentTotal ? formatCurrency(orderMonthTotal - spentTotal) : '$ 0'"
      />
    </div>

    <!-- Estadísticas de productos -->
    <section v-if="role === 'admin'" class="mb-12">
      <h2 class="text-2xl font-bold mb-6">Estadísticas de Productos</h2>
      <div class="grid grid-cols-1 gap-6 md:grid-cols-4">
        <box-dashboard 
          v-if="['financial', 'admin'].includes(role)"
          title="Total Referencias"
          :value="products ? products.toString() : '0'"
        />
        <box-dashboard 
          v-if="['financial', 'admin'].includes(role)"
          title="Costo Total Inventario"
          :value="productsStats?.costTotal ? formatCurrency(productsStats.costTotal) : '$ 0'"
        />
        <box-dashboard 
          title="Costo Total Venta"
          :value="productsStats?.priceTotal ? formatCurrency(productsStats.priceTotal) : '$ 0'"
        />
        <box-dashboard 
          title="Utilidad en Productos"
          :value="productsStats?.utilityTotal ? formatCurrency(productsStats.utilityTotal) : '$ 0'"
        />
      </div>
    </section>

    <!-- Accesos rápidos -->
    <section v-if="role === 'admin'" class="mb-12">
      <h2 class="text-2xl font-bold mb-6">Accesos Rápidos</h2>
      <div class="flex flex-wrap gap-3">
        <Link 
          v-for="(route, label) in quickLinks" 
          :key="route"
          :href="route"
          class="px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-lg transition-colors"
        >
          {{ label }}
        </Link>
      </div>
    </section>

    <!-- Tabla de últimas órdenes -->
    <section v-if="['admin', 'sales'].includes(role)">
      <h2 class="text-2xl font-bold mb-6">Últimas Órdenes</h2>
      <div class="bg-white rounded-lg shadow-sm overflow-x-auto">
        <table class="w-full whitespace-nowrap">
          <thead>
            <tr class="text-left bg-gray-50">
              <th v-for="header in tableHeaders" :key="header" class="px-6 py-4 font-semibold">
                {{ header }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="order in orders.data" :key="order.id" class="hover:bg-gray-50 transition-colors">
              <td class="border-t">
                <Link :href="`orders/${order.id}`" class="block px-6 py-4 hover:text-blue-600">
                  {{ formatDate(order.created_at) }}
                </Link>
              </td>
              <td class="border-t">
                <Link :href="`orders/${order.id}`" class="block px-6 py-4 hover:text-blue-600">
                  {{ order.number_order }}
                </Link>
              </td>
              <td class="border-t">
                <Link :href="`orders/${order.id}`" class="block px-6 py-4 hover:text-blue-600">
                  {{ order.client?.name }}
                </Link>
              </td>
              <td class="border-t">
                <Link :href="`orders/${order.id}`" class="block px-6 py-4 hover:text-blue-600">
                  <span :class="getOrderStateClass(order.order_state)">
                    {{ getOrderStateLabel(order.order_state) }}
                  </span>
                </Link>
              </td>
              <td class="border-t">
                <Link :href="`orders/${order.id}`" class="block px-6 py-4 hover:text-blue-600">
                  {{ formatFullName(order.responsible) }}
                </Link>
              </td>
              <td class="border-t">
                <Link :href="`orders/${order.id}`" class="block px-6 py-4 hover:text-blue-600">
                  {{ formatCurrency(order.balance) }}
                </Link>
              </td>
              <td class="border-t">
                <Link :href="`orders/${order.id}`" class="block px-6 py-4 hover:text-blue-600">
                  {{ formatCurrency(order.total) }}
                </Link>
              </td>
            </tr>
            <tr v-if="!orders.data.length">
              <td colspan="7" class="px-6 py-4 text-center text-gray-500">
                No hay órdenes para mostrar
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import BoxDashboard from '../../Shared/BoxDashboard.vue'

export default {
  components: {
    Link,
    Head,
    BoxDashboard,
  },
  layout: Layout,
  
  props: {
    total: Number,
    orders: Object,
    clientTotal: Number,
    balanceTotal: Number,
    products: Number,
    orderTotal: Number,
    spentTotal: Number,
    productsSoldout: Object,
    orderMonthTotal: Number,
    productsStats: Object
  },

  data() {
    return {
      role: this.$parent.auth.user.role,
      quickLinks: {
        'Tiendas': '/organizations',
        'Depósitos': '/deposits', 
        'Productos': '/products',
        'Marcas': '/brands',
        'Inventario': '/inventories',
        'Ventas': '/orders',
        'Gastos': '/spents',
        'Tasas de Cambio': '/taxes'
      },
      tableHeaders: [
        'Fecha',
        'Número',
        'Cliente', 
        'Estado',
        'Vendedor',
        'Abonado',
        'Total'
      ]
    }
  },

  methods: {
    formatDate(date) {
      return new Date(date).toLocaleString('es-VE', { 
        hour12: false,
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      })
    },

    formatCurrency(amount) {
      return new Intl.NumberFormat('es-VE', {
        style: 'currency',
        currency: 'USD'
      }).format(amount)
    },

    formatFullName(user) {
      if (!user) return ''
      return `${user.first_name} ${user.last_name}`
    },

    getOrderStateLabel(state) {
      const labels = {
        cancel: 'ANULADA',
        request: 'PENDIENTE POR PAGO',
        finish: 'FINALIZADA',
        pay: 'PAGADA'
      }
      return labels[state] || state
    },

    getOrderStateClass(state) {
      const classes = {
        cancel: 'text-red-600',
        request: 'text-yellow-600',
        finish: 'text-green-600',
        pay: 'text-blue-600'
      }
      return `font-medium ${classes[state] || ''}`
    }
  }
}
</script>
