<template>
  <div>
    <Head title="Crear productos" />
    
    <!-- Header -->
    <div class="flex items-center gap-4 mb-8">
      <button 
        class="p-3 text-white bg-teal-700 hover:bg-teal-800 rounded-lg transition-colors"
        title="Volver atrás"
        @click="back()"
      >
        <i class="fa fa-arrow-left"></i>
      </button>
      <h1 class="text-3xl font-bold">
        <Link 
          class="text-teal-600 hover:text-teal-700 transition-colors" 
          href="/products"
        >
          Productos
        </Link>
        <span class="text-gray-400 font-medium mx-2">/</span>
        <span>Crear</span>
      </h1>
    </div>

    <!-- Form -->
    <div class="max-w-4xl bg-white rounded-lg shadow-sm border border-gray-200">
      <form @submit.prevent="store">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 p-8">
          <!-- Basic Info -->
          <div class="lg:col-span-2">
            <h2 class="text-lg font-bold mb-4">Información básica</h2>
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <text-input
                v-model="form.code"
                :error="form.errors.code"
                label="Código *"
                placeholder="Ingrese el código del producto"
              />
              <text-input
                v-model="form.name"
                :error="form.errors.name"
                label="Nombre *" 
                placeholder="Ingrese el nombre del producto"
              />
              <text-input
                v-model="form.description"
                :error="form.errors.description"
                label="Descripción"
                placeholder="Ingrese una descripción"
                class="lg:col-span-2"
              />
            </div>
          </div>

          <!-- Pricing -->
          <div class="lg:col-span-2">
            <h2 class="text-lg font-bold mb-4">Precios y costos</h2>
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <text-input
                v-model="form.cost"
                :error="form.errors.cost"
                type="number"
                min="0"
                step="0.01"
                label="Costo *"
                placeholder="0.00"
              />
              <div v-for="(group, key) in groups" :key="key">
                <text-input
                  v-model="form.prices[key]"
                  :error="form.errors.price"
                  type="number"
                  min="0"
                  step="0.01"
                  :label="`Precio ${group.name} *`"
                  placeholder="0.00"
                />
              </div>
            </div>
          </div>

          <!-- Categories -->
          <div class="lg:col-span-2">
            <h2 class="text-lg font-bold mb-4">Categorización</h2>
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <select-input
                v-model="form.brand_id"
                :error="form.errors.brand_id"
                label="Marca *"
              >
                <option value="">Seleccionar marca</option>
                <option 
                  v-for="brand in brands" 
                  :key="brand.id" 
                  :value="brand.id"
                >
                  {{ brand.name }}
                </option>
              </select-input>

              <select-input
                v-model="form.product_category_id"
                :error="form.errors.product_category_id"
                label="Categoría *"
              >
                <option value="">Seleccionar categoría</option>
                <option 
                  v-for="category in productCategories" 
                  :key="category.id" 
                  :value="category.id"
                >
                  {{ category.name }}
                </option>
              </select-input>

              <select-input
                v-model="form.contable"
                :error="form.errors.contable"
                label="¿Es contable? *"
              >
                <option value="1">Sí</option>
                <option value="0">No</option>
              </select-input>

              <file-input
                v-model="form.photo"
                :error="form.errors.photo"
                accept="image/*"
                label="Foto del producto"
              />
            </div>
          </div>
        </div>

        <!-- Submit -->
        <div class="flex items-center justify-end px-8 py-4 bg-gray-50 border-t border-gray-100">
          <loading-button 
            :loading="form.processing" 
            class="px-6 py-3 bg-teal-600 hover:bg-teal-700 text-white font-medium rounded-lg transition-colors"
            type="submit"
          >
            Crear producto
          </loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import FileInput from '@/Shared/FileInput'

export default {
  components: {
    Head,
    Link,
    LoadingButton,
    SelectInput,
    TextInput,
    FileInput
  },
  layout: Layout,
  remember: 'form',
  props: {
    groups: {
      type: Object,
      required: true
    },
    brands: {
      type: Object,
      required: true
    },
    productCategories: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: this.$inertia.form({
        code: '',
        name: '',
        description: '',
        prices: this.groups.map(() => 0),
        brand_id: '',
        volumen: '',
        weight: '',
        quantity: 0,
        contable: 1,
        alias: '',
        line_name: '',
        cost: 0,
        product_category_id: '',
        photo: null
      }),
    }
  },
  methods: {
    back() {
      window.history.back()
    },
    store() {
      this.form.post('/products')
    },
  },
}
</script>
