<template>
  <div>
    <!-- Encabezado -->
    <div class="flex items-center justify-between mb-6">
      <button 
        class="mr-2 p-2 text-white bg-teal-800 hover:bg-teal-900 rounded transition-colors" 
        @click="back()"
        title="Volver atrás"
      >
        <i class="fa fa-arrow-left"></i>
      </button>
      <h1 class="text-3xl font-bold">Crear venta</h1>
    </div>

    <Head title="Crear venta" />

    <!-- Sección de cliente -->
    <div class="bg-white rounded-lg shadow-md overflow-hidden">
      <div class="p-6">
        <!-- Búsqueda de cliente -->
        <div class="flex gap-4 items-end mb-6">
          <text-input 
            v-model="form.client.identification"
            label="Cédula o identificación"
            placeholder="Ingrese número de identificación"
            class="flex-1"
          />
          <button 
            class="px-6 py-2.5 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-lg transition-colors"
            @click="findClient"
          >
            <i class="fa fa-search mr-2"></i>
            {{ textSearch }}
          </button>
        </div>

        <!-- Formulario de cliente -->
        <div v-if="showClientCreate" class="space-y-6">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <text-input 
              v-model="form.client.name"
              label="Nombre y Apellido"
              placeholder="Ingrese nombre completo"
            />

            <text-input
              v-model="form.client.email"
              label="Correo electrónico" 
              type="email"
              placeholder="ejemplo@correo.com"
            />

            <text-input
              v-model="form.client.phone"
              label="Teléfono"
              placeholder="Ingrese número de contacto"
            />

            <text-input
              v-model="form.client.address"
              label="Dirección"
              placeholder="Ingrese dirección completa"
            />
          </div>

          <div class="space-y-4">
            <text-input
              v-model="form.comentary"
              label="Observaciones"
              placeholder="Ingrese observaciones adicionales"
            />

            <div>
              <label class="block text-gray-700 mb-2">Método de pago</label>
              <select 
                v-model="form.method"
                class="form-select w-full rounded-lg border-gray-300"
              >
                <option value="">Seleccionar método</option>
                <option value="efectivo">Efectivo</option>
                <option value="transferencia">Transferencia Bs</option>
                <option value="transferencia-usd">Transferencia USD</option>
                <option value="biopago">Zelle</option>
                <option value="credito">Crédito</option>
              </select>
            </div>

            <text-input 
              v-if="form.method && form.method !== 'efectivo'"
              v-model="form.referencePay"
              label="Número de referencia"
              placeholder="Ingrese número de referencia"
            />
          </div>

          <div class="flex justify-end">
            <button 
              class="px-6 py-2.5 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-lg transition-colors"
              @click="generateOrder"
            >
              Siguiente
              <i class="fa fa-arrow-right ml-2"></i>
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import TextInput from '@/Shared/TextInput'
import { Head } from '@inertiajs/inertia-vue3'
import pickBy from 'lodash/pickBy'
import Layout from '@/Shared/Layout.vue'

export default {
  name: 'CreateClient',

  components: {
    TextInput,
    Head,
  },

  layout: Layout,

  props: {
    client: Object
  },

  data() {
    return {
      form: this.$inertia.form({
        client: {
          name: '',
          email: '',
          phone: '',
          address: '',
          identification: '',
        },
        search: {
          terms: '',
          deposit: '',
          brand_id: '',
          part_id: '',
          truck_model_id: ''
        },
        comentary: '',
        products: [],
        method: '',
        referencePay: '',
      }),
      textSearch: 'Buscar cliente',
      showClientCreate: false,
    }
  },

  methods: {
    back() {
      window.history.back()
    },

    findClient() {
      this.$inertia.get('/orders/client', pickBy(this.form), { preserveState: true })
      this.textSearch = 'Buscando...'

      setTimeout(() => {
        if (this.client) {
          this.textSearch = 'Buscar cliente'
          this.showClientCreate = true
          this.form.client = {
            name: this.client.name,
            email: this.client.email, 
            phone: this.client.phone,
            address: this.client.address,
            identification: this.client.identification,
          }
        } else {
          this.textSearch = 'Buscar cliente'
          this.$toast.warning('No se encontró el cliente. Complete el formulario para crear uno nuevo.')
          this.showClientCreate = true
        }
      }, 1500)
    },

    generateOrder() {
      this.form.post('/orders')
    }
  }
}
</script>
