<template>
  <div>
    <!-- Encabezado -->
    <div class="flex items-center justify-between mb-6">
      <button 
        class="mr-2 p-2 text-white bg-teal-800 hover:bg-teal-900 rounded transition-colors" 
        @click="back()"
        title="Volver atrás"
      >
        <i class="fa fa-arrow-left"></i>
      </button>
      <h1 class="text-3xl font-bold">Nueva Compra</h1>
    </div>

    <Head title="Nueva Compra" />

    <!-- Información de la compra -->
    <div class="bg-white rounded-lg shadow-md overflow-hidden mb-6">
      <div class="p-6 border-b">
        <h3 class="text-xl font-semibold mb-4">Información de la Compra</h3>
        
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <!-- Selector de Proveedor -->
          <select-input
            v-model="form.provider_id"
            :error="form.errors.provider_id"
            label="Proveedor *"
            class="w-full"
          >
            <option value="">Seleccionar proveedor</option>
            <option 
              v-for="provider in providers" 
              :key="provider.id" 
              :value="provider.id"
            >
              {{ provider.name }} - {{ provider.company_name }}
            </option>
          </select-input>

          <!-- Número de Factura -->
          <text-input
            v-model="form.invoice_number"
            :error="form.errors.invoice_number"
            label="Número de Factura *"
            placeholder="Ingrese el número de factura..."
          />

          <!-- Fecha de Vencimiento -->
          <text-input
            v-model="form.due_date"
            :error="form.errors.due_date"
            type="date"
            label="Fecha de Vencimiento *"
            class="w-full"
          />
        </div>
      </div>
    </div>

    <!-- Buscador de productos -->
    <div class="bg-white rounded-lg shadow-md overflow-hidden mb-6">
      <div class="p-6 border-b">
        <h3 class="text-xl font-semibold">Búsqueda de Productos</h3>
        
        <div class="mt-4">
          <text-input 
            v-model="form.search.terms"
            label="Buscar por nombre, código o descripción"
            placeholder="Ingrese términos de búsqueda"
            class="w-full lg:w-1/2"
          />
        </div>
      </div>

      <!-- Tabla de resultados de búsqueda -->
      <div class="overflow-x-auto">
        <table class="w-full whitespace-nowrap">
          <thead>
            <tr class="text-left bg-gray-50">
              <th class="py-3 px-6 font-semibold">Código</th>
              <th class="py-3 px-6 font-semibold">Producto</th>
              <th class="py-3 px-6 font-semibold">Stock</th>
              <th class="py-3 px-6 font-semibold">Precio Compra</th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="product in products" 
              :key="product.id"
              class="hover:bg-gray-50 cursor-pointer transition-colors"
              @click="selectProduct(product)"
            >
              <td class="py-3 px-6">{{ product?.code }}</td>
              <td class="py-3 px-6">{{ product?.name }}</td>
              <td class="py-3 px-6">{{ product?.quantity }}</td>
              <td class="py-3 px-6">{{ formatCurrency(product?.cost) }}</td>
            </tr>
            <tr v-if="!products?.length">
              <td colspan="4" class="py-4 px-6 text-center text-gray-500">
                No se encontraron productos
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Lista de productos seleccionados -->
    <div class="bg-white rounded-lg shadow-md overflow-hidden">
      <div class="p-6 border-b">
        <h3 class="text-xl font-semibold">Productos a Comprar</h3>
      </div>

      <div class="overflow-x-auto">
        <table class="w-full">
          <thead class="bg-gray-50">
            <tr>
              <th class="p-3 text-left">Código</th>
              <th class="p-3 text-left">Producto</th>
              <th class="p-3 text-center">Cantidad</th>
              <th class="p-3 text-right">Precio</th>
              <th class="p-3 text-right">Total</th>
              <th class="p-3"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(product, index) in form.products" :key="product.id" class="border-t">
              <td class="p-3">{{ product.code }}</td>
              <td class="p-3">{{ product.name }}</td>
              <td class="p-3">
                <text-input
                  v-model="form.products[index].quantity"
                  type="number"
                  class="w-20 text-center"
                />
              </td>
              <td class="p-3">
                <text-input
                  v-model="form.products[index].price"
                  type="number"
                  class="w-24 text-right"
                />
              </td>
              <td class="p-3 text-right">
                ${{ (form.products[index].price * product.quantity).toFixed(2) }}
              </td>
              <td class="p-3 text-center">
                <button
                  @click="removeProduct(product)"
                  class="p-2 text-white bg-red-500 hover:bg-red-600 rounded-full transition-colors"
                  title="Eliminar producto"
                >
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
          <tfoot class="bg-gray-50">
            <tr>
              <td colspan="2" class="p-3">
                <select-input
                  v-model="form.deposit_id"
                  :error="form.errors.deposit_id"
                  label="Depósito destino *"
                  class="w-full"
                >
                  <option value="">Seleccione un depósito</option>
                  <option v-for="deposit in deposits" :key="deposit.id" :value="deposit.id">
                    {{ deposit.name }}
                  </option>
                </select-input>
              </td>
              <td colspan="2" class="p-3 text-right font-bold">Total:</td>
              <td class="p-3 text-right">
                <div class="text-xl font-bold">${{ getTotal() }}</div>
              </td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>

      <div class="p-6 bg-gray-50">
        <button 
          @click="generate"
          class="btn-blue text-lg px-8 py-3"
          :disabled="!form.products.length"
        >
          <i class="fa fa-check mr-2"></i>
          Generar Compra
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import pickBy from 'lodash/pickBy'
import debounce from 'lodash/debounce'

export default {
  components: {
    Head,
    Link,
    TextInput,
    SelectInput,
  },
  
  layout: Layout,
  
  props: {
    products: Object,
    deposits: Object,
    providers: Object,
  },

  data() {
    return {
      form: this.$inertia.form({
        provider_id: '',
        invoice_number: '',
        due_date: '',
        deposit_id: '',
        search: {
          terms: '',
          deposit: '',
        },
        products: []
      }),
    }
  },

  methods: {
    back() {
      window.history.back()
    },

    searchProducts() {
      this.$inertia.get('/shoppings/create', 
        pickBy({ search: this.form.search }), 
        {
          preserveState: true,
          preserveScroll: true,
          replace: true
        }
      )
    },

    selectProduct(product) {
      const exists = this.form.products.some(p => p.id === product.id)
      
      if (!exists) {
        this.form.products.push({
          ...product,
          quantity: 1,
          price: product.cost || 0
        })
      }
    },

    removeProduct(product) {
      if (confirm('¿Está seguro que desea eliminar este producto?')) {
        this.form.products = this.form.products.filter(p => p.id !== product.id)
      }
    },

    getTotal() {
      return this.form.products.reduce((total, product) => {
        return total + (product.price * product.quantity)
      }, 0).toFixed(2)
    },

    formatCurrency(value) {
      return new Intl.NumberFormat('es-US', {
        style: 'currency',
        currency: 'USD'
      }).format(value || 0)
    },

    generate() {
      this.form.post('/shoppings')
    }
  },

  watch: {
    'form.search': {
      handler: debounce(function() {
        this.searchProducts()
      }, 300),
      deep: true
    }
  }
}
</script>