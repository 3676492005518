<template>
  <div>
    <Head title="Editar proveedor" />
    
    <!-- Header -->
    <div class="flex items-center gap-4 mb-8">
      <button 
        class="p-3 text-white bg-teal-700 hover:bg-teal-800 rounded-lg transition-colors"
        title="Volver atrás"
        @click="back()"
      >
        <i class="fa fa-arrow-left"></i>
      </button>
      <h1 class="text-3xl font-bold">
        <Link 
          class="text-teal-600 hover:text-teal-700 transition-colors" 
          href="/providers"
        >
          Proveedores
        </Link>
        <span class="text-gray-400 font-medium mx-2">/</span>
        <Link 
          class="text-teal-600 hover:text-teal-700 transition-colors"
          :href="`/providers/${provider.id}`"
        >
          {{ form.company_name }}
        </Link>
        <span class="text-gray-400 font-medium mx-2">/</span>
        <span>Editar</span>
      </h1>
    </div>

    <!-- Form -->
    <div class="bg-white rounded-lg shadow-sm border border-gray-200">
      <form @submit.prevent="update">
        <div class="p-8">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <!-- Información básica -->
            <text-input 
              v-model="form.name" 
              :error="form.errors.name"
              label="Nombre completo *"
              placeholder="Ingrese el nombre del contacto..."
            />

            <text-input 
              v-model="form.company_name" 
              :error="form.errors.company_name"
              label="Nombre de la empresa *"
              placeholder="Ingrese el nombre de la empresa..."
            />

            <text-input 
              v-model="form.tax_id" 
              :error="form.errors.tax_id"
              label="RIF *"
              placeholder="Ingrese el número de identificación fiscal..."
            />

            <text-input 
              v-model="form.email" 
              :error="form.errors.email"
              label="Correo electrónico"
              type="email"
              placeholder="ejemplo@empresa.com"
            />

            <!-- Información de contacto -->
            <text-input 
              v-model="form.phone" 
              :error="form.errors.phone"
              label="Teléfono"
              type="tel"
              placeholder="Ingrese número telefónico..."
            />

            <text-input 
              v-model="form.website" 
              :error="form.errors.website"
              label="Sitio web"
              type="url"
              placeholder="https://www.ejemplo.com"
            />

            <!-- Ubicación -->
            <text-input 
              v-model="form.address" 
              :error="form.errors.address"
              label="Dirección"
              placeholder="Ingrese dirección completa..."
            />

            <text-input 
              v-model="form.city" 
              :error="form.errors.city"
              label="Ciudad"
              placeholder="Ingrese la ciudad..."
            />

            <text-input 
              v-model="form.country" 
              :error="form.errors.country"
              label="País"
              placeholder="Ingrese el país..."
            />

            <!-- Notas -->
            <div class="md:col-span-2">
              <text-area
                v-model="form.notes"
                :error="form.errors.notes"
                label="Notas adicionales"
                placeholder="Ingrese notas o comentarios adicionales..."
              />
            </div>
          </div>
        </div>

        <!-- Submit -->
        <div class="flex items-center justify-between px-8 py-4 bg-gray-50 border-t border-gray-100">
          <button 
            class="text-red-600 hover:text-red-700 font-medium transition-colors"
            type="button"
            @click="destroy"
          >
            Eliminar proveedor
          </button>
          <loading-button 
            :loading="form.processing" 
            class="px-6 py-3 bg-teal-600 hover:bg-teal-700 text-white font-medium rounded-lg transition-colors"
            type="submit"
          >
            Actualizar proveedor
          </loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import LoadingButton from '@/Shared/LoadingButton'
import TextArea from '@/Shared/TextAreaInput'

export default {
  name: 'ProvidersEdit',

  components: {
    Head,
    Link,
    LoadingButton,
    TextInput,
    TextArea,
  },

  layout: Layout,

  props: {
    provider: Object,
  },

  remember: 'form',

  data() {
    return {
      form: this.$inertia.form({
        name: this.provider?.name,
        company_name: this.provider?.company_name,
        email: this.provider?.email,
        phone: this.provider?.phone,
        address: this.provider?.address,
        city: this.provider?.city,
        country: this.provider?.country,
        tax_id: this.provider?.tax_id,
        website: this.provider?.website,
        notes: this.provider?.notes
      }),
    }
  },

  methods: {
    back() {
      window.history.back()
    },

    update() {
      this.form.put(`/providers/${this.provider.id}`)
    },

    destroy() {
      if (confirm('¿Está seguro que desea eliminar este proveedor?')) {
        this.$inertia.delete(`/providers/${this.provider.id}`)
      }
    },
  },
}
</script>