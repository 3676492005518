<template>
  <div>
    <Head :title="`${form.name}`" />

    <!-- Botón de regreso y título -->
    <div class="flex items-center mb-8">
      <button 
        class="mr-4 p-2.5 bg-teal-800 hover:bg-teal-900 text-white rounded-lg transition-colors" 
        @click="back()"
        title="Volver"
      >
        <i class="fa fa-arrow-left"></i>
      </button>
      <h1 class="text-3xl font-bold flex items-center">
        <Link 
          class="text-blue-600 hover:text-blue-700 transition-colors" 
          href="/clients"
        >
          Clientes
        </Link>
        <span class="mx-2 text-gray-400">/</span>
        <span>{{ form.name }}</span>
        <span class="ml-2 text-gray-600">
          ({{ formatCurrency(form.balance) }})
        </span>
      </h1>
    </div>

    <!-- Lista de órdenes -->
    <div class="bg-white rounded-lg shadow-sm overflow-x-auto mb-6">
      <div class="flex items-center px-6 py-4 bg-teal-800 text-white">
        <h2 class="text-lg font-semibold">Lista de órdenes en proceso</h2>
      </div>
      
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr class="text-left bg-gray-50">
            <th class="px-6 py-4 font-semibold">#</th>
            <th class="px-6 py-4 font-semibold">Fecha</th>
            <th class="px-6 py-4 font-semibold">Estado</th>
            <th class="px-6 py-4 font-semibold">Observación</th>
            <th class="px-6 py-4 font-semibold">Cliente</th>
            <th class="px-6 py-4 font-semibold">Responsable</th>
            <th class="px-6 py-4 font-semibold">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="order in orders" 
            :key="order.id" 
            :class="{
              'hover:bg-gray-50 focus-within:bg-gray-50': true,
              'bg-red-100': order.order_state === 'cancel'
            }"
          >
            <td class="border-t">
              <Link 
                :href="`/orders/${order.id}`" 
                class="block px-6 py-4 hover:text-blue-600 transition-colors"
              >
                {{ order.id }}
              </Link>
            </td>
            <td class="border-t">
              <Link 
                :href="`/orders/${order.id}`" 
                class="block px-6 py-4 hover:text-blue-600 transition-colors"
              >
                {{ order.date }}
              </Link>
            </td>
            <td class="border-t">
              <Link 
                :href="`/orders/${order.id}`" 
                class="block px-6 py-4 hover:text-blue-600 transition-colors"
              >
                <span :class="{
                  'px-2 py-1 rounded text-sm font-medium': true,
                  'bg-red-100 text-red-800': order.order_state === 'cancel',
                  'bg-yellow-100 text-yellow-800': order.order_state === 'request',
                  'bg-green-100 text-green-800': order.order_state === 'finish',
                  'bg-blue-100 text-blue-800': order.order_state === 'pay'
                }">
                  {{ getOrderStateLabel(order.order_state) }}
                </span>
              </Link>
            </td>
            <td class="border-t">
              <Link 
                :href="`/orders/${order.id}`" 
                class="block px-6 py-4 hover:text-blue-600 transition-colors"
              >
                {{ order.comentary }}
              </Link>
            </td>
            <td class="border-t">
              <Link 
                :href="`/orders/${order.id}`" 
                class="block px-6 py-4 hover:text-blue-600 transition-colors"
              >
                {{ order.client.name }}
              </Link>
            </td>
            <td class="border-t">
              <Link 
                :href="`/orders/${order.id}`" 
                class="block px-6 py-4 hover:text-blue-600 transition-colors"
              >
                {{ order.responsible ? `${order.responsible.first_name} ${order.responsible.last_name}` : 'Transacción directa' }}
              </Link>
            </td>
            <td class="border-t">
              <Link 
                :href="`/orders/${order.id}`" 
                class="block px-6 py-4 hover:text-blue-600 transition-colors"
              >
                {{ formatCurrency(order.total) }}
              </Link>
            </td>
          </tr>

          <tr v-if="!orders.length">
            <td class="px-6 py-8 text-center text-gray-500 border-t" colspan="7">
              No hay órdenes disponibles
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Registro de pagos -->
    <div class="bg-white rounded-lg shadow-sm mb-6">
      <div class="flex items-center px-6 py-4 bg-teal-800 text-white rounded-t-lg">
        <h2 class="text-lg font-semibold">Registrar pagos</h2>
      </div>
      
      <div class="p-6">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label class="block text-gray-700 mb-2">Método de pago</label>
            <select 
              v-model="pay.method" 
              class="form-select w-full rounded-lg border-gray-300 focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="">Seleccionar método</option>
              <option 
                v-for="method in payment_methods" 
                :key="method.id" 
                :value="method.name"
              >
                {{ method.name }}
              </option>
            </select>
          </div>

          <text-input 
            v-model="pay.amount" 
            type="number" 
            step="0.10" 
            :error="pay.errors.long" 
            label="Monto" 
          />

          <text-input 
            v-if="['transferencia', 'transferencia-usd', 'zelle', 'pago-movil'].includes(pay.method)"
            v-model="pay.reference" 
            :error="pay.errors.reference" 
            label="Referencia" 
          />

          <div>
            <label class="block text-gray-700 mb-2">Fecha</label>
            <input 
              v-model="pay.date" 
              type="date"
              class="form-input w-full rounded-lg border-gray-300 focus:border-blue-500 focus:ring-blue-500" 
            />
          </div>
        </div>

        <div class="mt-6">
          <button 
            @click="storePay" 
            class="px-4 py-2 bg-teal-800 hover:bg-teal-900 text-white font-medium rounded-lg transition-colors"
          >
            Agregar pago
          </button>
        </div>
      </div>
    </div>

    <!-- Formulario de actualización -->
    <div class="bg-white rounded-lg shadow-sm">
      <div class="flex items-center px-6 py-4 bg-teal-800 text-white rounded-t-lg">
        <h2 class="text-lg font-semibold">Actualizar cliente</h2>
      </div>

      <form @submit.prevent="update">
        <div class="p-6 grid grid-cols-1 md:grid-cols-2 gap-6">
          <text-input 
            v-model="form.name" 
            :error="form.errors.name" 
            label="Nombre y Apellido" 
          />
          <text-input 
            v-model="form.email" 
            :error="form.errors.email" 
            label="Correo electrónico" 
          />
          <text-input 
            v-model="form.phone" 
            :error="form.errors.phone" 
            label="Teléfono" 
          />
          <text-input 
            v-model="form.identification" 
            :error="form.errors.identification" 
            label="Alias o Cédula" 
          />
          <text-input 
            v-model="form.companyName" 
            :error="form.errors.companyName" 
            label="Empresa" 
          />
          <text-input 
            v-model="form.address" 
            :error="form.errors.address" 
            label="Dirección" 
          />
          
          <div>
            <label class="block text-gray-700 mb-2">Tipo de cliente</label>
            <select 
              v-model="form.typeClient"
              class="form-select w-full rounded-lg border-gray-300 focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="">Seleccionar tipo</option>
              <option value="freelancer">Freelancer</option>
              <option value="spot">SPOT</option>
              <option value="a">A</option>
              <option value="b">B</option>
              <option value="c">C</option>
            </select>
          </div>
        </div>

        <div class="flex items-center justify-end px-6 py-4 bg-gray-50 border-t">
          <loading-button 
            :loading="form.processing" 
            class="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-lg transition-colors"
            type="submit"
          >
            Actualizar cliente
          </loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import TrashedMessage from '@/Shared/TrashedMessage'

export default {
  components: {
    Head,
    Link,
    LoadingButton,
    SelectInput,
    TextInput,
    TrashedMessage,
  },
  layout: Layout,
  props: {
    client: Object,
    organizations: Array,
    orders: Array,
    payment_methods: Object,
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        name: this.client.name,
        email: this.client.email,
        phone: this.client.phone,
        identification: this.client.identification,
        companyName: this.client.companyName,
        address: this.client.address,
        balance: this.client.balance,
        typeClient: this.client.typeClient,
      }),
      pay: this.$inertia.form({
        client_id: this.client.id,
        method: '',
        amount: 0,
        reference: '',
        date: '',
      }),
    }
  },
  methods: {
    back() {
      window.history.back()
    },
    update() {
      this.form.put(`/clients/${this.client.id}`)
    },
    destroy() {
      if (confirm('¿Está seguro que desea eliminar este cliente?')) {
        this.$inertia.delete(`/clients/${this.client.id}`)
      }
    },
    storePay() {
      this.pay.post('/payments')
    },
    restore() {
      if (confirm('¿Está seguro que desea restaurar este cliente?')) {
        this.$inertia.put(`/clients/${this.client.id}/restore`)
      }
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP'
      }).format(value)
    },
    getOrderStateLabel(state) {
      const labels = {
        cancel: 'ANULADA',
        request: 'PENDIENTE POR PAGO', 
        finish: 'FINALIZADA',
        pay: 'PAGADA'
      }
      return labels[state] || state
    }
  },
}
</script>
