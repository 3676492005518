<template>
  <div>
    <Head title="Crear cliente" />

    <!-- Header y título -->
    <div class="flex items-center mb-8">
      <button 
        class="mr-4 p-2.5 bg-teal-800 hover:bg-teal-900 text-white rounded-lg transition-colors" 
        @click="back()"
        title="Volver"
      >
        <i class="fa fa-arrow-left"></i>
      </button>
      <h1 class="text-3xl font-bold flex items-center">
        <Link 
          class="text-blue-600 hover:text-blue-700 transition-colors" 
          href="/clients"
        >
          Clientes
        </Link>
        <span class="mx-2 text-gray-400">/</span>
        <span>Crear</span>
      </h1>
    </div>

    <!-- Formulario -->
    <div class="bg-white rounded-lg shadow-sm">
      <form @submit.prevent="store">
        <div class="p-6">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <text-input 
              v-model="form.companyName" 
              :error="form.errors.companyName"
              label="Empresa"
              placeholder="Ingrese el nombre de la empresa..."
            />

            <text-input 
              v-model="form.name" 
              :error="form.errors.name"
              label="Nombre y Apellido"
              placeholder="Ingrese el nombre completo..."
            />

            <text-input 
              v-model="form.email" 
              :error="form.errors.email"
              label="Correo electrónico"
              type="email"
              placeholder="ejemplo@correo.com"
            />

            <text-input 
              v-model="form.identification" 
              :error="form.errors.identification"
              label="Alias o Cédula"
              placeholder="Ingrese identificación..."
            />

            <text-input 
              v-model="form.phone" 
              :error="form.errors.phone"
              label="Teléfono"
              type="tel"
              placeholder="Ingrese número telefónico..."
            />

            <text-input 
              v-model="form.address" 
              :error="form.errors.address"
              label="Dirección"
              placeholder="Ingrese dirección completa..."
            />

            <div>
              <label class="block text-gray-700 mb-2">Tipo de cliente</label>
              <select 
                v-model="form.typeClient" 
                class="w-full form-select rounded-lg border-gray-300 focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="">Seleccionar tipo...</option>
                <option value="freelancer">Freelancer</option>
                <option value="spot">SPOT</option>
                <option value="a">A</option>
                <option value="b">B</option>
                <option value="c">C</option>
              </select>
              <div v-if="form.errors.typeClient" class="text-red-500 text-sm mt-1">
                {{ form.errors.typeClient }}
              </div>
            </div>
          </div>
        </div>

        <div class="px-6 py-4 bg-gray-50 border-t border-gray-100 flex items-center justify-end">
          <loading-button 
            :loading="form.processing" 
            class="px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-lg transition-colors"
          >
            Crear cliente
          </loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'

export default {
  name: 'ContactsCreate',

  components: {
    Head,
    Link,
    LoadingButton,
    SelectInput,
    TextInput,
  },

  layout: Layout,

  props: {
    organizations: Array,
  },

  remember: 'form',

  data() {
    return {
      form: this.$inertia.form({
        companyName: '',
        name: '',
        email: '',
        identification: '',
        phone: '',
        address: '',
        typeClient: ''
      }),
    }
  },

  methods: {
    back() {
      window.history.back()
    },

    store() {
      this.form.post('/clients')
    },
  },
}
</script>
