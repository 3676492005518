<template>
  <div>
    <Head title="Editar producto" />
    
    <!-- Header -->
    <div class="flex items-center gap-4 mb-8">
      <button 
        class="p-3 text-white bg-teal-700 hover:bg-teal-800 rounded-lg transition-colors"
        title="Volver atrás"
        @click="back()"
      >
        <i class="fa fa-arrow-left"></i>
      </button>
      <h1 class="text-3xl font-bold">
        <Link 
          class="text-teal-600 hover:text-teal-700 transition-colors" 
          href="/products"
        >
          Productos
        </Link>
        <span class="text-gray-400 font-medium mx-2">/</span>
        <Link 
          class="text-teal-600 hover:text-teal-700 transition-colors"
          :href="`/products/${product.id}`"
        >
          {{ form.name }}
        </Link>
        <span class="text-gray-400 font-medium mx-2">/</span>
        <span>Editar</span>
      </h1>
    </div>

    <!-- Form -->
    <div class="max-w-4xl bg-white rounded-lg shadow-sm border border-gray-200">
      <form @submit.prevent="update">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 p-8">
          <!-- Basic Info -->
          <div class="lg:col-span-2">
            <h2 class="text-lg font-bold mb-4">Información básica</h2>
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <text-input
                v-model="form.code"
                :error="form.errors.code"
                label="Código *"
                placeholder="Ingrese el código del producto"
              />
              <text-input
                v-model="form.name"
                :error="form.errors.name"
                label="Nombre *"
                placeholder="Ingrese el nombre del producto" 
              />
              <text-input
                v-model="form.description"
                :error="form.errors.description"
                label="Descripción"
                placeholder="Ingrese una descripción"
              />
            </div>
          </div>

          <!-- Prices -->
          <div class="lg:col-span-2">
            <h2 class="text-lg font-bold mb-4">Precios</h2>
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <text-input
                v-model="form.cost"
                :error="form.errors.cost"
                label="Costo *"
                type="number"
                placeholder="Ingrese el costo"
              />
              <text-input
                v-for="(group, key) in groups"
                :key="group.id"
                v-model.number="form.prices[key]"
                type="number"
                :error="form.errors.prices"
                :label="`Precio ${group.name} *`"
                placeholder="Ingrese el precio"
              />
            </div>
          </div>

          <!-- Categories -->
          <div class="lg:col-span-2">
            <h2 class="text-lg font-bold mb-4">Categorización</h2>
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <select-input
                v-model="form.brand_id"
                :error="form.errors.brand_id"
                label="Marca *"
              >
                <option value="">Seleccionar marca</option>
                <option
                  v-for="brand in brands"
                  :key="brand.id"
                  :value="brand.id"
                >
                  {{ brand.name }}
                </option>
              </select-input>

              <select-input
                v-model="form.product_category_id"
                :error="form.errors.product_category_id"
                label="Categoría *"
              >
                <option value="">Seleccionar categoría</option>
                <option
                  v-for="category in productCategories"
                  :key="category.id"
                  :value="category.id"
                >
                  {{ category.name }}
                </option>
              </select-input>

              <select-input
                v-model="form.contable"
                :error="form.errors.contable"
                label="Contable *"
              >
                <option value="">Seleccionar opción</option>
                <option value="0">No</option>
                <option value="1">Sí</option>
              </select-input>

              <file-input
                v-model="form.photo"
                :error="form.errors.photo"
                type="file"
                accept="image/*"
                label="Foto del producto"
              />
            </div>
          </div>
        </div>

        <!-- Actions -->
        <div class="flex items-center justify-between px-8 py-4 bg-gray-50 border-t">
          <button
            type="button"
            class="text-red-600 hover:text-red-700 font-medium transition-colors"
            @click="destroy"
          >
            Eliminar producto
          </button>
          
          <loading-button
            :loading="form.processing"
            class="btn-blue"
          >
            Actualizar producto
          </loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import FileInput from '@/Shared/FileInput'

export default {
  name: 'ProductEdit',

  components: {
    Head,
    Link,
    LoadingButton,
    SelectInput,
    TextInput,
    FileInput
  },

  layout: Layout,

  remember: 'form',

  props: {
    product: Object,
    groups: Object,
    brands: Object,
    parts: Object,
    productCategories: Object
  },

  data() {
    return {
      form: this.$inertia.form({
        _method: 'put',
        code: this.product.code,
        id: this.product.id,
        name: this.product.name,
        line_name: this.product.line_name,
        description: this.product.description,
        volumen: this.product.volumen,
        weight: this.product.weight,
        prices: [
          this.product?.prices[0]?.price
        ],
        brand_id: this.product.brand_id,
        part_id: this.product.part_id,
        alias: this.product.alias,
        contable: this.product.contable,
        cost: this.product.cost,
        photo: null,
        product_category_id: this.product.product_category_id
      }),
    }
  },

  methods: {
    back() {
      window.history.back()
    },

    update() {
      this.form.post(`/products/${this.form.id}`)
    },

    destroy() {
      if (confirm('¿Está seguro que desea eliminar este producto?')) {
        this.$inertia.delete(`/products/${this.product.id}`)
      }
    },
  },
}
</script>
