<template>
  <div>
    <!-- Encabezado -->
    <div class="flex items-center justify-between mb-6">
      <button 
        class="mr-2 p-2 text-white bg-teal-800 hover:bg-teal-900 rounded transition-colors" 
        @click="back()"
        title="Volver atrás"
      >
        <i class="fa fa-arrow-left"></i>
      </button>
      <h1 class="text-3xl font-bold">Crear venta</h1>
    </div>

    <Head title="Crear venta" />

    <!-- Sección de cliente -->
    <div class="bg-white rounded-lg shadow-md overflow-hidden mb-6">
      <div class="p-6">
        <find-or-create-client @addClient="takeClient" />
        
        <!-- Formulario de cliente -->
        <div v-if="showClientCreate" class="grid grid-cols-1 md:grid-cols-2 gap-6 mt-8">
          <text-input 
            v-model="form.client.name"
            label="Nombre y Apellido"
            placeholder="Ingrese el nombre completo"
          />

          <text-input
            v-model="form.client.identification" 
            label="Alias o Cédula"
            placeholder="Ingrese identificación"
          />

          <text-input
            v-model="form.client.phone"
            label="Teléfono"
            placeholder="Ingrese número de contacto" 
          />

          <text-input
            v-model="form.client.address"
            label="Dirección"
            placeholder="Ingrese dirección completa"
          />

          <text-input
            v-model="form.client.balance"
            label="Monto adeudado" 
            disabled
          />

          <div>
            <label class="block text-gray-700 mb-2">Tipo de cliente</label>
            <select 
              v-model="form.client.typeClient"
              class="form-select w-full rounded-lg border-gray-300"
            >
              <option value="">Seleccionar tipo</option>
              <option value="freelancer">Freelancer</option>
              <option value="spot">SPOT</option>
              <option value="a">A</option>
              <option value="b">B</option>
              <option value="c">C</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <!-- Sección de productos -->
    <div class="bg-white rounded-lg shadow-md overflow-hidden mb-6">
      <div class="p-6">
        <div class="flex flex-col sm:flex-row items-center justify-between mb-6 gap-4">
          <div class="flex gap-2">
            <button 
              class="btn-blue flex items-center"
              @click="listProducts = !listProducts"
              title="Ver lista de productos"
            >
              <i class="fa fa-list mr-2"></i>
              Lista
            </button>

            <button 
              class="btn-blue flex items-center"
              @click="addQuickProduct()"
              title="Agregar producto rápido"
            >
              <i class="fa fa-plus mr-2"></i>
              Producto rápido
            </button>
          </div>

          <text-input
            v-model="form.search.terms"
            placeholder="Buscar productos..."
            class="w-full sm:w-64"
          >
            <template #append>
              <i class="fa fa-search text-gray-400"></i>
            </template>
          </text-input>
        </div>

        <!-- Lista de productos -->
        <div v-if="listProducts" class="mb-6">
          <div class="overflow-x-auto rounded-lg border">
            <table class="w-full">
              <thead class="bg-gray-50">
                <tr>
                  <th class="p-3 text-left">Código</th>
                  <th class="p-3 text-left">Producto</th>
                  <th class="p-3 text-center">Disponibles</th>
                  <th class="p-3 text-right">Precio</th>
                </tr>
              </thead>
              <tbody>
                <tr 
                  v-for="product in products.data" 
                  :key="product.id"
                  class="hover:bg-gray-50 cursor-pointer border-t"
                  @click="selectProduct(product)"
                >
                  <td class="p-3">{{ product.code }}</td>
                  <td class="p-3">{{ product.name }}</td>
                  <td class="p-3 text-center">{{ product.quantity }}</td>
                  <td class="p-3 text-right">${{ product.prices[0].price }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Carrito de compras -->
        <div class="overflow-x-auto rounded-lg border">
          <table class="w-full">
            <thead class="bg-gray-50">
              <tr>
                <th class="p-3 text-left">Código</th>
                <th class="p-3 text-left">Producto</th>
                <th class="p-3 text-center">Disponible</th>
                <th class="p-3 text-center">Cantidad</th>
                <th class="p-3 text-right">Precio</th>
                <th class="p-3 text-right">Total</th>
                <th class="p-3"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(product, index) in form.products" :key="product.id" class="border-t">
                <td class="p-3">
                  <text-input 
                    v-if="product.quick"
                    v-model="form.products[index].code"
                    placeholder="Código"
                  />
                  <span v-else>{{ product.code }}</span>
                </td>
                <td class="p-3">
                  <text-input
                    v-if="product.quick"
                    v-model="form.products[index].name"
                    placeholder="Nombre"
                  />
                  <span v-else>{{ product.name }}</span>
                </td>
                <td class="p-3 text-center">{{ product.stock }}</td>
                <td class="p-3">
                  <text-input
                    v-model="form.products[index].quantity"
                    type="number"
                    :max="product.stock"
                    class="w-20 text-center"
                  />
                </td>
                <td class="p-3">
                  <text-input
                    v-model="form.products[index].price"
                    type="number"
                    :disabled="!product.quick && auth.user.role == 'sales'"
                    class="w-24 text-right"
                  />
                </td>
                <td class="p-3 text-right">
                  ${{ (form.products[index].price * product.quantity).toFixed(2) }}
                </td>
                <td class="p-3 text-center">
                  <button
                    @click="removeProduct(product)"
                    class="p-2 text-white bg-red-500 hover:bg-red-600 rounded-full transition-colors"
                    title="Eliminar producto"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
            <tfoot class="bg-gray-50">
              <tr>
                <td colspan="5" class="p-3 text-right font-bold">Total:</td>
                <td class="p-3 text-right">
                  <div class="text-xl font-bold">${{ getTotal() }}</div>
                  <div class="text-sm text-gray-600">Bs. {{ (getTotal() * form.change).toFixed(2) }}</div>
                </td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>

    <!-- Opciones de crédito -->
    <div class="bg-blue-100 rounded-lg p-4 mb-6">
      <label class="inline-flex items-center cursor-pointer">
        <input 
          v-model="form.isCredit"
          type="checkbox"
          class="form-checkbox h-5 w-5 text-blue-600"
        >
        <span class="ml-2 text-lg font-semibold">Venta a crédito</span>
      </label>
    </div>

    <!-- Detalles adicionales -->
    <div class="bg-white rounded-lg shadow-md overflow-hidden mb-6">
      <div class="p-6 space-y-6">
        <!-- Campo para ingresar el número correlativo de la orden -->
        <!-- <text-input
          v-model="form.number_order"
          label="Correlativo"
          placeholder="Número único de orden (opcional)"
        /> -->

        <!-- Campo para ingresar el número de orden de compra del cliente -->
        <!-- <text-input
          v-model="form.order_buy"
          label="Orden de compra"
          placeholder="Número de orden de compra (si aplica)"
        /> -->

        <text-input
          v-model="form.date"
          label="Fecha"
          type="date"
          required
        />

        <text-input
          v-model="form.change"
          label="Tasa de cambio"
          type="number"
          step="0.01"
        />

        <text-area-input
          v-model="form.comentary"
          label="Observaciones"
          placeholder="Ingrese observaciones importantes"
          required
        />
      </div>
    </div>

    <!-- Botón de generar venta -->
    <div class="flex justify-end">
      <button 
        @click="generateOrder"
        class="btn-blue text-lg px-8 py-3"
      >
        <i class="fa fa-check mr-2"></i>
        Generar Venta
      </button>
    </div>
  </div>
</template>

<script>
import Layout from '../../Shared/Layout.vue'
import TextInput from '@/Shared/TextInput'
import pickBy from 'lodash/pickBy'
import { Head } from '@inertiajs/inertia-vue3'
import SelectInput from '@/Shared/SelectInput.vue'
import FindOrCreateClient from '../../Shared/FindOrCreateClient.vue'
import TextAreaInput from '@/Shared/TextAreaInput'

export default {
  components: {
    SelectInput,
    TextInput,
    Head,
    FindOrCreateClient,
    TextAreaInput
  },

  layout: Layout,

  props: {
    products: Object,
    client: Object,
    brands: Object,
    parts: Object,
    models: Object,
    deposits: Object,
    regions: Object,
    change: Number,
    auth: Object
  },

  data() {
    return {
      getQuantity(inventory) {
        return inventory.reduce((total, inv) => total + inv.quantity, 0)
      },

      getTotal() {
        return this.form.products.reduce((total, product) => {
          return total + (product.price * product.quantity)
        }, 0).toFixed(2)
      },

      selected: null,
      options: ['list', 'of', 'options'],
      
      form: this.$inertia.form({
        date: '',
        search: {
          terms: '',
          deposit: '',
          brand_id: '',
          part_id: '',
          truck_model_id: '',
          region_id: '',
        },
        isCredit: false,
        number_order: null,
        client: {
          name: '',
          email: '',
          cedula: '',
          telefono: '',
          address: '',
          identification: '',
          balance: 0,
          typeClient: '',
        },
        comentary: '',
        products: [],
        method: '',
        referencePay: '',
        change: this.change,
        estimate: true,
        order_buy: ''
      }),

      listProducts: false,
      showClientCreate: false,
      textSearch: 'Buscar cliente',
    }
  },

  watch: {
    'form.search.terms': 'onSearchProductsChange',
    'form.search.deposit': 'onSearchProductsChange', 
    'form.search.brand_id': 'onSearchProductsChange',
    'form.search.part_id': 'onSearchProductsChange',
    'form.search.truck_model_id': 'onSearchProductsChange',
    'form.search.region_id': 'onSearchProductsChange'
  },

  mounted() {
    this.form.date = new Date().toISOString().substr(0,10)
  },

  methods: {
    takeClient(client) {
      this.form.client = client
    },

    getDeposits(deposits) {
      return deposits
    },

    back() {
      window.history.back()
    },

    addQuickProduct() {
      this.form.products.push({
        id: 'quick',
        code: '',
        name: '',
        deposit: '',
        quantity: 1,
        price: 0,
        quick: true
      })
    },

    getProductStock(inventory, deposito) {
      const found = inventory.find(inv => inv.deposit.id == deposito)
      return found ? found.quantity : ''
    },

    selectProduct(product) {
      if (product.quantity <= 0) {
        return alert('No hay stock disponible de este producto')
      }

      const exists = this.form.products.some(p => p.id === product.id)
      
      if (!exists) {
        this.form.products.push({
          ...product,
          stock: product.quantity,
          quantity: 1,
          price: product?.prices[0]['price'],
          quick: false
        })
      }
    },

    removeProduct(product) {
      if (confirm('¿Está seguro que desea eliminar este producto?')) {
        this.form.products = this.form.products.filter(p => p.id !== product.id)
      }
    },

    findClient() {
      this.$inertia.get('/orders/create', pickBy(this.form), { preserveState: true })
      this.textSearch = 'Buscando...'

      setTimeout(() => {
        if (this.client) {
          this.textSearch = 'Buscar cliente'
          this.showClientCreate = true
          this.form.client = {
            name: this.client.name,
            email: this.client.email,
            phone: this.client.phone,
            address: this.client.address,
            identification: this.client.identification,
            balance: this.client.balance,
            typeClient: this.client.typeClient,
            companyName: this.client.companyName,
          }
        } else {
          this.textSearch = 'Buscar cliente'
          alert('No se encontró el cliente. Puede crear uno nuevo en el formulario.')
          this.showClientCreate = true
        }
      }, 1500)
    },

    generateOrder() {
      this.form.post('/orders')
    },

    onSearchProductsChange() {
      this.$inertia.get('/orders/create', 
        pickBy({ search: this.form.search }), 
        {
          preserveState: true,
          preserveScroll: true,
          replace: true,
        }
      )
    },

    onSelectedProduct(consultant) {
      console.log(consultant)
    },

    updateSelected(newSelected) {
      this.selectProduct = newSelected
    },
  },
}
</script>
