<template>
    <div class="bg-white shadow-sm border border-slate-200 border-radius rounded-xl p-3">
        <div class="border-black">
            <h5 class="mb-2 text-slate-800 text-xl">
                {{ title ?? 'Titulo' }}
            </h5>
        </div>
        <div>
            <h3 class="text-2xl font-bold">{{ value }}</h3>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BoxDashboard',
    props: {
        title: String,
        value: {
            type: String,
            default: '0'
        }
    }
}
</script>