<template>
  <div>
    <!-- Selección del método de pago -->
    <div v-if="mode === 'select'" class="grid grid-cols-2 gap-4">
      <button 
        v-for="method in payment_methods" 
        :key="method.id"
        @click="selectPaymentMethod(method)"
        class="btn-blue p-4 text-lg font-medium rounded-lg hover:opacity-90 transition-opacity"
      >
        {{ method.name }}
      </button>
    </div>

    <!-- Formulario de pago -->
    <div v-if="mode === 'form'" class="space-y-6">
      <form @submit.prevent="processPay">
        <!-- Campo monto en Bs si aplica -->
        <text-input 
          v-if="payment_method.change_currency_id"
          v-model="amountAlternate"
          type="number"
          step="0.01"
          class="mb-4"
          label="Monto en Bolívares"
          placeholder="Ingrese el monto en Bs"
        />

        <!-- Campo monto principal -->
        <text-input
          v-model="form.amount"
          :disabled="payment_method.change_currency_id"
          type="number" 
          step="0.01"
          class="mb-4"
          label="Monto en USD"
          placeholder="Ingrese el monto en USD"
        />

        <!-- Campo referencia si aplica -->
        <text-input
          v-if="!payment_method.change"
          v-model="form.reference"
          class="mb-4"
          label="Referencia"
          placeholder="Ingrese el número de referencia"
          :error="form.errors.reference"
        />

        <!-- Mostrar cambio si excede el balance -->
        <div v-if="hasChange" class="p-4 bg-blue-50 rounded-lg mb-4">
          <p class="text-xl font-bold text-blue-900">
            Cambio a devolver: $ {{ changeAmount.toFixed(2) }}
          </p>
        </div>

        <!-- Botones de acción -->
        <div class="flex items-center justify-end gap-4">
          <button 
            type="button"
            @click="cancelPayment"
            class="px-6 py-2 bg-gray-800 text-white rounded-lg hover:bg-gray-900 transition-colors"
          >
            Cancelar
          </button>
          <button 
            type="submit"
            class="px-6 py-2 bg-teal-800 text-white rounded-lg hover:bg-teal-900 transition-colors"
          >
            Procesar pago
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import TextInput from './TextInput.vue'

export default {
  components: { 
    TextInput 
  },

  props: {
    payment_methods: {
      type: Object,
      required: true
    },
    balance: {
      type: Number,
      required: true
    },
    change: {
      type: Number,
      required: true
    },
    order: {
      type: Object,
      required: true
    },
    quick: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      mode: 'select',
      payment_method: null,
      form: this.$inertia.form({
        method: '',
        amount: 0,
        reference: '',
        quick: true,
        order_id: null,
        amount_bs: 0
      }),
      amountAlternate: 0
    }
  },

  computed: {
    hasChange() {
      return this.balance < this.form.amount
    },

    changeAmount() {
      return this.form.amount - this.balance
    }
  },

  watch: {
    amountAlternate(val) {
      this.form.amount = val / this.change
    }
  },

  methods: {
    resetForm() {
      this.mode = 'select'
      this.payment_method = null
      this.form = this.$inertia.form({
        method: '',
        amount: 0,
        reference: '',
        quick: true,
        order_id: null,
        amount_bs: 0
      })
      this.amountAlternate = 0
    },

    selectPaymentMethod(method) {
      this.mode = 'form'
      this.payment_method = method
    },

    cancelPayment() {
      this.mode = 'select'
    },

    validatePayment() {
      if (!this.payment_method.change && !this.form.reference) {
        alert('Debe ingresar una referencia')
        return false
      }

      const totalPayment = parseInt(this.form.amount) + parseInt(this.order.balance)
      
      if (this.order.total < totalPayment) {
        const newAmount = this.form.amount - (parseFloat(this.form.amount) - parseFloat(this.order.total))
        
        if (parseInt(newAmount) + parseInt(this.order.balance) > this.order.total) {
          alert('El monto del pago excede el balance pendiente')
          return false
        }
        
        this.form.amount = newAmount
      }

      return true
    },

    processPay() {
      if (!this.validatePayment()) {
        return
      }

      this.form.method = this.payment_method.name
      this.form.order_id = this.order.id
      this.form.amount_bs = this.amountAlternate

      this.form.post('/orders/pay')
      this.resetForm()
    }
  }
}
</script>