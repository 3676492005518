<template>
  <div>
    <!-- Encabezado -->
    <div class="flex items-center justify-between mb-6">
      <button 
        class="mr-2 p-2 text-white bg-teal-800 hover:bg-teal-900 rounded transition-colors" 
        @click="back()"
        title="Volver atrás"
      >
        <i class="fa fa-arrow-left"></i>
      </button>
      <h1 class="text-3xl font-bold">Gestión de Inventario</h1>
    </div>

    <Head title="Gestión de Inventario" />

    <!-- Buscador de productos -->
    <div class="bg-white rounded-lg shadow-md overflow-hidden mb-6">
      <div class="p-6 border-b">
        <h3 class="text-xl font-semibold">Búsqueda de Productos</h3>
        
        <div class="mt-4">
          <text-input 
            v-model="form.search.terms"
            label="Buscar por nombre, código o descripción"
            placeholder="Ingrese términos de búsqueda"
            class="w-full lg:w-1/2"
          />
        </div>
      </div>

      <!-- Tabla de resultados de búsqueda -->
      <div class="overflow-x-auto">
        <table class="w-full whitespace-nowrap">
          <thead>
            <tr class="text-left bg-gray-50">
              <th class="py-3 px-6 font-semibold">Código</th>
              <th class="py-3 px-6 font-semibold">Producto</th>
              <th class="py-3 px-6 font-semibold">Disponibles</th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="product in products.data" 
              :key="product.id"
              class="hover:bg-gray-50 cursor-pointer transition-colors"
              @click="addProduct(product)"
            >
              <td class="py-3 px-6">{{ product?.code }}</td>
              <td class="py-3 px-6">{{ product?.name }}</td>
              <td class="py-3 px-6">{{ product?.quantity }}</td>
            </tr>
            <tr v-if="!products?.data?.length">
              <td colspan="3" class="py-4 px-6 text-center text-gray-500">
                No se encontraron productos
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Lista de productos seleccionados -->
    <div class="bg-white rounded-lg shadow-md overflow-hidden">
      <div class="p-6 border-b">
        <h3 class="text-xl font-semibold">Productos Seleccionados</h3>
      </div>

      <div class="overflow-x-auto">
        <table class="w-full whitespace-nowrap">
          <thead>
            <tr class="text-left bg-gray-50">
              <th class="py-3 px-6 font-semibold">Cantidad</th>
              <th class="py-3 px-6 font-semibold">Depósito</th>
              <th class="py-3 px-6 font-semibold">Código</th>
              <th class="py-3 px-6 font-semibold">Producto</th>
              <th class="py-3 px-6 font-semibold">Stock Actual</th>
              <th class="py-3 px-6"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="product in items" :key="product.id" class="hover:bg-gray-50">
              <td class="py-3 px-6">
                <text-input
                  v-model="quantities.qtys[product.id]"
                  type="number"
                  min="1"
                  placeholder="Cantidad"
                  class="w-24"
                />
              </td>
              <td class="py-3 px-6">
                <select-input 
                  v-model="quantities.deposits[product.id]"
                  class="w-48"
                >
                  <option value="">Seleccionar depósito</option>
                  <option 
                    v-for="deposit in deposits" 
                    :key="deposit.id"
                    :value="deposit.name"
                  >
                    {{ deposit.name }}
                  </option>
                </select-input>
              </td>
              <td class="py-3 px-6">{{ product.code }}</td>
              <td class="py-3 px-6">{{ product.name }}</td>
              <td class="py-3 px-6">{{ product.quantity }}</td>
              <td class="py-3 px-6">
                <button 
                  @click="removeProduct(product)"
                  class="p-2 text-red-600 hover:text-red-800 transition-colors"
                  title="Eliminar producto"
                >
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
            <tr v-if="!items.length">
              <td colspan="6" class="py-4 px-6 text-center text-gray-500">
                No hay productos agregados
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="p-6 bg-gray-50">
        <loading-button 
          class="btn-blue"
          :loading="quantities.processing"
          @click="generate"
        >
          <i class="fa fa-save mr-2"></i>
          Guardar Entrada de Productos
        </loading-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import pickBy from 'lodash/pickBy'
import debounce from 'lodash/debounce'

export default {
  components: {
    Head,
    Link,
    TextInput,
    SelectInput,
  },
  layout: Layout,
  
  props: {
    products: Object,
    deposits: Array,
  },

  data() {
    return {
      quantities: this.$inertia.form({
        qtys: [],
        products: [],
        deposits: [],
      }),

      form: this.$inertia.form({
        search: {
          terms: '',
          deposit: '',
          brand_id: '',
          part_id: '',
          truck_model_id: '',
          region_id: '',
        },
      }),

      items: []
    }
  },

  watch: {
    'form.search': {
      handler: debounce(function() {
        this.searchProducts()
      }, 300),
      deep: true
    }
  },

  methods: {
    back() {
      window.history.back()
    },

    searchProducts() {
      this.$inertia.get('/inventories/create', 
        pickBy({ search: this.form.search }), 
        {
          preserveState: true,
          preserveScroll: true,
          replace: true
        }
      )
    },

    addProduct(product) {
      if (!this.items.find(item => item.id === product.id)) {
        this.items.push(product)
        this.$nextTick(() => {
          this.quantities.qtys[product.id] = 1
        })
      }
    },

    removeProduct(product) {
      if (confirm('¿Está seguro que desea eliminar este producto?')) {
        this.items = this.items.filter(item => item.id !== product.id)
        delete this.quantities.qtys[product.id]
        delete this.quantities.deposits[product.id]
      }
    },

    generate() {
      if (!this.items.length) {
        alert('Debe agregar al menos un producto')
        return
      }

      this.quantities.products = this.items.map(item => item.id)
      this.quantities.post('/inventories')
    }
  }
}
</script>
