<template>
  <div>
    <Head title="Crear proveedor" />

    <!-- Header y título -->
    <div class="flex items-center mb-8">
      <button 
        class="mr-4 p-2.5 bg-teal-800 hover:bg-teal-900 text-white rounded-lg transition-colors" 
        @click="back()"
        title="Volver"
      >
        <i class="fa fa-arrow-left"></i>
      </button>
      <h1 class="text-3xl font-bold flex items-center">
        <Link 
          class="text-blue-600 hover:text-blue-700 transition-colors" 
          href="/providers"
        >
          Proveedores
        </Link>
        <span class="mx-2 text-gray-400">/</span>
        <span>Crear</span>
      </h1>
    </div>

    <!-- Formulario -->
    <div class="bg-white rounded-lg shadow-sm">
      <form @submit.prevent="store">
        <div class="p-6">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <!-- Información básica -->
            <text-input 
              v-model="form.name" 
              :error="form.errors.name"
              label="Nombre completo *"
              placeholder="Ingrese el nombre del contacto..."
            />

            <text-input 
              v-model="form.company_name" 
              :error="form.errors.company_name"
              label="Nombre de la empresa *"
              placeholder="Ingrese el nombre de la empresa..."
            />

            <text-input 
              v-model="form.tax_id" 
              :error="form.errors.tax_id"
              label="RIF *"
              placeholder="Ingrese el número de identificación fiscal..."
            />

            <text-input 
              v-model="form.email" 
              :error="form.errors.email"
              label="Correo electrónico"
              type="email"
              placeholder="ejemplo@empresa.com"
            />

            <!-- Información de contacto -->
            <text-input 
              v-model="form.phone" 
              :error="form.errors.phone"
              label="Teléfono"
              type="tel"
              placeholder="Ingrese número telefónico..."
            />

            <text-input 
              v-model="form.website" 
              :error="form.errors.website"
              label="Sitio web"
              type="url"
              placeholder="https://www.ejemplo.com"
            />

            <!-- Ubicación -->
            <text-input 
              v-model="form.address" 
              :error="form.errors.address"
              label="Dirección"
              placeholder="Ingrese dirección completa..."
            />

            <text-input 
              v-model="form.city" 
              :error="form.errors.city"
              label="Ciudad"
              placeholder="Ingrese la ciudad..."
            />

            <text-input 
              v-model="form.country" 
              :error="form.errors.country"
              label="País"
              placeholder="Ingrese el país..."
            />

            <!-- Notas -->
            <div class="md:col-span-2">
              <text-area
                v-model="form.notes"
                :error="form.errors.notes"
                label="Notas adicionales"
                placeholder="Ingrese notas o comentarios adicionales..."
              />
            </div>
          </div>
        </div>

        <div class="px-6 py-4 bg-gray-50 border-t border-gray-100 flex items-center justify-end">
          <loading-button 
            :loading="form.processing" 
            class="px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-lg transition-colors"
          >
            Crear proveedor
          </loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import LoadingButton from '@/Shared/LoadingButton'
import TextArea from '@/Shared/TextAreaInput'

export default {
  name: 'ProvidersCreate',

  components: {
    Head,
    Link,
    LoadingButton,
    TextInput,
    TextArea,
  },

  layout: Layout,

  remember: 'form',

  data() {
    return {
      form: this.$inertia.form({
        name: '',
        company_name: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        country: '',
        tax_id: '',
        website: '',
        notes: ''
      }),
    }
  },

  methods: {
    back() {
      window.history.back()
    },

    store() {
      this.form.post('/providers')
    },
  },
}
</script>
