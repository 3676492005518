<style>
  .menu-item {
    color: var(--text-color) !important;
  }
  .menu-item:hover {
    color: var(--hover-color) !important;
  }
</style>
<template>
  <div>
    <div class="mb-4">
      <Link class="group flex items-center py-3" href="/">
        <icon name="dashboard" class="mr-2 w-4 h-4" :class="isUrl('') ? 'fill-white' : 'fill-gray-600 group-hover:fill-white menu-item'" :style="{'--text-color': account.textSidebarColor ?? 'black', '--hover-color': account.textHoverSidebarColor}" />
        <div :class="isUrl('') ? 'menu-item font-bold' : 'text-gray-600 group-hover:text-white menu-item'" :style="{'--text-color': account.textSidebarColor ?? 'black', '--hover-color': account.textHoverSidebarColor}">Escritorio</div>
      </Link>
    </div>
    <!--<div class="mb-4" v-if="role == 'admin'">-->
    <!--  <Link class="group flex items-center py-3" href="/requests">-->
    <!--    <i :class="isUrl('requests') ? 'fa fa-file mr-2 text-white' : 'fa fa-file mr-2 text-gray-400 group-hover:fill-white'" />-->
    <!--    <div :class="isUrl('requests') ? 'text-white' : 'text-gray-300 group-hover:text-white'">Solicitudes</div>-->
    <!--  </Link>-->
    <!--</div>-->
    <div v-if="role == 'admin' || role == 'endline' || role == 'storer'" class="mb-4">
      <Link class="group flex items-center py-3" href="/products">
        <i :class="isUrl('products') ? 'fa fa-shopping-cart mr-2 text-white' : 'fa fa-shopping-cart mr-2 text-gray-600 group-hover:fill-white menu-item'" :style="{'--text-color': account.textSidebarColor ?? 'black', '--hover-color': account.textHoverSidebarColor}" />
        <div :class="isUrl('products') ? 'menu-item font-bold' : 'text-gray-500 group-hover:text-white menu-item'" :style="{'--text-color': account.textSidebarColor ?? 'black', '--hover-color': account.textHoverSidebarColor}">Productos</div>
      </Link>
    </div>
    <div class="mb-4" v-if="role == 'admin' || role == 'sales' || role == 'financial'">
      <Link class="group flex items-center py-3" href="/clients">
        <i :class="isUrl('clients') ? 'fa fa-address-book mr-2 text-white' : 'fa fa-address-book mr-2 text-gray-600 group-hover:fill-white menu-item'" :style="{'--text-color': account.textSidebarColor ?? 'black', '--hover-color': account.textHoverSidebarColor}" />
        <div :class="isUrl('clients') ? 'menu-item font-bold' : 'text-gray-500 group-hover:text-white menu-item'" :style="{'--text-color': account.textSidebarColor ?? 'black', '--hover-color': account.textHoverSidebarColor}">Clientes</div>
      </Link>
    </div>
    <div class="mb-4" v-if="role == 'admin' || role == 'endline' || role == 'storer'">
      <Link class="group flex items-center py-3" href="/inventories">
        <i :class="isUrl('inventories') ? 'fa fa-th-large mr-2 text-white' : 'fa fa-th-large mr-2 text-gray-600 group-hover:fill-white menu-item'" :style="{'--text-color': account.textSidebarColor ?? 'black', '--hover-color': account.textHoverSidebarColor}" />
        <div :class="isUrl('inventories') ? 'menu-item font-bold' : 'text-gray-500 group-hover:text-white menu-item'" :style="{'--text-color': account.textSidebarColor ?? 'black', '--hover-color': account.textHoverSidebarColor}">Inventario</div>
      </Link>
    </div>
    <!--<div class="mb-4" v-if="role == 'storer' || role == 'admin' || role == 'endline'">
      <Link class="group flex items-center py-3" href="/returns">
        <i :class="isUrl('returns') ? 'fa fa-th-large mr-2 text-white' : 'fa fa-th-large mr-2 text-gray-400 group-hover:fill-white'" />
        <div :class="isUrl('returns') ? 'text-white' : 'text-gray-300 group-hover:text-white'">Devolucion</div>
      </Link>
    </div> -->
    <div v-if="role == 'sales' || role == 'admin' || role == 'storer' || role == 'financial'" class="mb-4">
      <Link class="group flex items-center py-3" href="/orders">
        <i :class="isUrl('orders') ? 'fa fa-shopping-cart mr-2 text-white' : 'fa fa-shopping-cart mr-2 text-gray-600 group-hover:fill-white menu-item'" :style="{'--text-color': account.textSidebarColor ?? 'black', '--hover-color': account.textHoverSidebarColor}" />
        <div :class="isUrl('orders') ? 'menu-item font-bold' : 'text-gray-500 group-hover:text-white menu-item'" :style="{'--text-color': account.textSidebarColor ?? 'black', '--hover-color': account.textHoverSidebarColor}">Ventas</div>
      </Link>
    </div>
    <div v-if="role == 'admin' || role == 'financial'" class="mb-4">
      <Link class="group flex items-center py-3" href="/shoppings">
        <i :class="isUrl('shoppings') ? 'fa fa-money mr-2 text-white' : 'fa fa-money mr-2 text-gray-600 group-hover:fill-white menu-item'" :style="{'--text-color': account.textSidebarColor ?? 'black', '--hover-color': account.textHoverSidebarColor}" />
        <div class="flex items-center gap-2">
          <div :class="isUrl('shoppings') ? 'menu-item font-bold' : 'text-gray-500 group-hover:text-white menu-item'" :style="{'--text-color': account.textSidebarColor ?? 'black', '--hover-color': account.textHoverSidebarColor}">Compras</div>
          <span class="px-1.5 py-0.5 text-xs font-medium bg-blue-100 text-blue-800 rounded">BETA</span>
        </div>
      </Link>
    </div>
    <div v-if="role == 'admin' || role == 'financial'" class="mb-4">
      <Link class="group flex items-center py-3" href="/providers">
        <i :class="isUrl('providers') ? 'fa fa-users mr-2 text-white' : 'fa fa-users mr-2 text-gray-600 group-hover:fill-white menu-item'" :style="{'--text-color': account.textSidebarColor ?? 'black', '--hover-color': account.textHoverSidebarColor}" />
        <div :class="isUrl('providers') ? 'menu-item font-bold' : 'text-gray-500 group-hover:text-white menu-item'" :style="{'--text-color': account.textSidebarColor ?? 'black', '--hover-color': account.textHoverSidebarColor}">Proveedores</div>
        <span class="ml-1 px-1.5 py-0.5 text-xs font-medium bg-blue-100 text-blue-800 rounded">BETA</span>
      </Link>
    </div>
    <div v-if="role == 'admin' || role == 'financial'" class="mb-4">
      <Link class="group flex items-center py-3" href="/spents">
        <i :class="isUrl('spents') ? 'fa fa-money mr-2 text-white' : 'fa fa-money mr-2 text-gray-600 group-hover:fill-white menu-item'" :style="{'--text-color': account.textSidebarColor ?? 'black', '--hover-color': account.textHoverSidebarColor}" />
        <div :class="isUrl('spents') ? 'menu-item font-bold' : 'text-gray-500 group-hover:text-white menu-item'" :style="{'--text-color': account.textSidebarColor ?? 'black', '--hover-color': account.textHoverSidebarColor}">Gastos</div>
      </Link>
    </div>
    <div v-if="role == 'sales' || role == 'admin'" class="mb-4">
      <Link class="group flex items-center py-3" href="/reports">
        <i :class="isUrl('reports') ? 'fa fa-file-o mr-2 text-white' : 'fa fa-file mr-2 text-gray-600 group-hover:fill-white menu-item'" :style="{'--text-color': account.textSidebarColor ?? 'black', '--hover-color': account.textHoverSidebarColor}" />
        <div :class="isUrl('reports') ? 'menu-item font-bold' : 'text-gray-500 group-hover:text-white menu-item'" :style="{'--text-color': account.textSidebarColor ?? 'black', '--hover-color': account.textHoverSidebarColor}">Reportes</div>
      </Link>
    </div>
  </div>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'

export default {
  components: {
    Icon,
    Link,
  },
  props: {
    account: Object
  },
  data()
  {
    return {
      role: this.$parent.auth.user.role
    }
  },
  methods: {
    back() {
      window.history.back();
    },
    isUrl(...urls) {
      let currentUrl = this.$page.url.substr(1)
      if (urls[0] === '') {
        return currentUrl === ''
      }
      return urls.filter((url) => currentUrl.startsWith(url)).length
    },
  },
}
</script>
