<template>
  <div class="container mx-auto">
    <!-- Barra de búsqueda y filtros -->
    <div class="flex justify-between mb-4" v-if="mode === 'cart'">
      <text-input 
        placeholder="Buscar producto por nombre o código..." 
        id="search" 
        class="p-2 border border-gray-300 rounded w-2/3"
        v-model="form.search.terms"
        autofocus
      />
      <select 
        v-model="form.search.category" 
        class="p-2 border border-gray-300 rounded w-1/3 ml-2"
      >
        <option value="">Todas las categorías</option>
        <option 
          v-for="category in product_categories" 
          :key="category.id" 
          :value="category.id"
        >
          {{ category.name }}
        </option>
      </select>
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-4">
      <!-- Lista de productos -->
      <div class="col-span-2">
        <div v-if="mode === 'cart'" class="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 max-h-[600px] overflow-y-auto">
          <product-card 
            v-for="product in products.data" 
            :key="product.id" 
            :product="product" 
            @addCart="addCart" 
            :change="change" 
            :cop_change="cop_change"
          />
        </div>
      </div>

      <!-- Carrito de compras -->
      <div v-if="mode === 'cart'" class="bg-white rounded-lg shadow-lg p-6">
        <h2 class="text-xl font-bold mb-6">Carrito de Compras</h2>

        <div class="space-y-4 max-h-[400px] overflow-y-auto">
          <div 
            v-for="(product, key) in cart" 
            :key="product.id" 
            class="flex items-center justify-between p-3 border rounded hover:bg-gray-50"
          >
            <div class="flex items-center">
              <button 
                @click="removeProduct(product)" 
                class="p-2 text-white bg-red-500 hover:bg-red-600 rounded"
                title="Eliminar producto"
              >
                <i class="fa fa-trash"></i>
              </button>
              <div class="ml-4">
                <h3 class="font-medium">{{ product?.name }}</h3>
                <p class="text-sm text-gray-500">Stock: {{ product?.stock }} unidades</p>
              </div>
            </div>
            <div class="flex items-center space-x-2">
              <text-input-rounded 
                type="number" 
                :max="product?.stock"
                min="1"
                v-model="cart[key]['quantity']" 
                class="w-20 text-center" 
              />
              <div class="text-right">
                <div class="font-medium">${{ cart[key]['price'] }} c/u</div>
                <div class="text-sm text-gray-600">
                  Total: ${{ (cart[key]['price'] * product.quantity).toFixed(2) }}
                </div>
              </div>
            </div>
          </div>

          <div v-if="!cart.length" class="text-center text-gray-500 py-8">
            El carrito está vacío
          </div>
        </div>

        <!-- Totales -->
        <div class="mt-6 pt-4 border-t space-y-2">
          <div class="flex justify-between font-medium">
            <span>Total USD:</span>
            <span>${{ getTotal() }}</span>
          </div>
          <div class="flex justify-between text-gray-600">
            <span>Total Bs:</span>
            <span>{{ (getTotal() * change).toFixed(2) }}</span>
          </div>
          <div class="flex justify-between text-gray-600">
            <span>Total COP:</span>
            <span>{{ ((getTotal() * change) * cop_change).toFixed(1) }}</span>
          </div>
        </div>

        <div class="mt-4 flex items-center">
          <input 
            type="checkbox" 
            v-model="withoutClient" 
            id="withoutClient"
            class="rounded border-gray-300"
          >
          <label for="withoutClient" class="ml-2 text-gray-700">
            Venta sin cliente registrado
          </label>
        </div>
      </div>

      <!-- Formulario de cliente -->
      <div v-if="mode === 'client'" class="col-span-3 bg-white rounded-lg shadow-lg p-6">
        <h2 class="text-xl font-bold mb-6">Datos del Cliente</h2>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-6 p-4">
          <div class="flex items-end gap-2">
            <text-input 
              v-model="form.client.identification" 
              label="Identificación o Alias"
              class="flex-1"
            />
            <button 
              class="btn-blue h-10 px-4"
              @click="findClient" 
              type="button"
            >
              <i class="fa fa-search" />
            </button>
          </div>

          <text-input 
            v-model="form.client.name" 
            label="Nombre Completo" 
          />

          <text-input 
            v-model="form.client.phone" 
            label="Teléfono"
          />

          <text-input 
            v-model="form.client.address" 
            label="Dirección" 
          />

          <text-input 
            v-model="form.client.balance" 
            disabled
            label="Saldo Pendiente" 
          />

          <div>
            <label class="block text-gray-700 mb-2">Tipo de Cliente</label>
            <select 
              v-model="form.client.typeClient" 
              class="form-select w-full rounded-lg border-gray-300"
            >
              <option value="">Seleccionar tipo</option>
              <option value="freelancer">Freelancer</option>
              <option value="spot">SPOT</option>
              <option value="a">A</option>
              <option value="b">B</option>
              <option value="c">C</option>
            </select>
          </div>
        </div>
      </div>

      <!-- Datos de pago -->
      <div v-if="mode === 'pay'" class="bg-white rounded-lg shadow-lg p-6">
        <h2 class="text-xl font-bold mb-6">Método de Pago</h2>

        <div class="space-y-4">
          <div>
            <label class="block text-gray-700 mb-2">Seleccione método de pago</label>
            <select 
              v-model="form.method"
              class="form-select w-full rounded-lg border-gray-300"
            >
              <option value="">Seleccionar método</option>
              <option value="efectivo">Efectivo</option>
              <option value="transferencia">Transferencia Bs</option>
              <option value="transferencia-usd">Transferencia USD</option>
              <option value="biopago">Zelle</option>
              <option value="credito">Crédito</option>
            </select>
          </div>

          <text-input 
            v-if="form.method && form.method !== 'efectivo'"
            v-model="form.referencePay" 
            label="Número de Referencia"
          />

          <text-input 
            v-if="form.method === 'efectivo'"
            v-model="cambio"
            type="number"
            label="Monto recibido" 
          />
        </div>
      </div>

      <!-- Comprobantes -->
      <div v-if="mode === 'print'" class="bg-white rounded-lg shadow-lg p-6">
        <h2 class="text-xl font-bold mb-6">Comprobantes de Pago</h2>
        
        <div class="flex gap-4">
          <button class="btn-blue" type="button">
            <i class="fa fa-print mr-2"></i> Nota de entrega
          </button>
          <button class="btn-blue" type="button">
            <i class="fa fa-print mr-2"></i> Factura
          </button>
          <button class="btn-blue" type="button">
            <i class="fa fa-print mr-2"></i> Ticket
          </button>
        </div>
      </div>
    </div>

    <!-- Barra inferior fija -->
    <div class="fixed bottom-0 left-0 right-0 bg-white shadow-lg p-4 flex items-center justify-between">
      <div class="space-x-4">
        <span v-if="cambio" class="font-medium">
          Cambio: ${{ (cambio - getTotal()).toFixed(2) }}
        </span>
        <span class="font-medium">
          Total: ${{ getTotal() }} | Bs.{{ (getTotal() * change).toFixed(2) }} | 
          COP {{ ((getTotal() * change) * cop_change).toFixed(1) }}
        </span>
      </div>

      <div class="space-x-2">
        <button 
          v-if="mode !== 'cart'"
          @click="back"
          class="px-6 py-2 bg-gray-600 hover:bg-gray-700 text-white font-medium rounded-lg"
        >
          Atrás
        </button>
        <button 
          @click="next"
          class="px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-lg"
        >
          Siguiente
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import LayoutFull from '../../Shared/LayoutFull.vue'
import ProductCard from '../../Shared/ProductCard.vue'
import TextInput from '../../Shared/TextInput.vue'
import TextInputRounded from '../../Shared/TextInputRounded.vue'
import pickBy from 'lodash/pickBy'
import SelectInput from '../../Shared/SelectInput.vue'

export default {
  components: { 
    ProductCard, 
    TextInput, 
    TextInputRounded, 
    SelectInput 
  },

  layout: LayoutFull,

  props: {
    products: Object,
    change: Number,
    cop_change: Number,
    client: Object,
    product_categories: Object
  },

  data() {
    return {
      cambio: 0,
      showClientCreate: false,
      mode: 'cart',
      form: this.$inertia.form({
        search: {
          terms: '',
          category: ''
        },
        client: {
          identification: ''
        },
        products: null,
        isCredit: false,
        comentary: 'Compra rápida!',
        referencePay: '',
        quick: true
      }),
      cart: [],
      withoutClient: true
    }
  },

  watch: {
    'form.search.terms': function() {
      this.onSearchProductsChange()
    },

    'form.search.category': function() {
      this.onSearchProductsChange()
    },

    'products': function(val) {
      if(this.products.data.length === 1) {
        const product = this.products.data[0]
        
        if(product.code === this.form.search.terms) {
          if (product.quantity <= 0) {
            this.form.search.terms = ''
            return alert('No hay stock disponible de este producto')
          }

          const existingProduct = this.cart.find(p => p.id === product.id)
          
          if (existingProduct) {
            existingProduct.quantity++
          } else {
            this.cart.push({
              ...product,
              stock: product.quantity,
              quantity: 1,
              price: product.prices[0].price,
              quick: false
            })
          }

          this.form.search.terms = ''
        }
      }
    }
  },

  methods: {
    getTotal() {
      return this.cart.reduce((total, product) => {
        return total + (product.price * product.quantity)
      }, 0).toFixed(2)
    },

    back() {
      if(this.mode === 'client') {
        this.mode = 'cart'
      }
    },

    next() {
      if(this.mode === 'cart') {
        if(this.cart.length === 0) {
          return alert("Debe agregar productos al carrito para continuar")
        }

        if(this.withoutClient) {
          this.form.client = {
            name: "Cliente por defecto",
            email: null,
            phone: "0414409888391",
            address: "San diego",
            identification: "0000",
            balance: 0,
            typeClient: "a",
            companyName: null,
            id: 1
          }
          this.form.products = this.cart
          return this.form.post('/orders')
        }
        this.mode = 'client'
      } else if(this.mode === 'client') {
        this.form.products = this.cart
        this.form.post('/orders')
      } else if(this.mode === 'pay') {
        this.mode = 'print'
      }
    },

    removeProduct(product) {
      if (confirm('¿Está seguro que desea eliminar este producto?')) {
        this.cart = this.cart.filter(p => p.id !== product.id)
      }
    },

    addCart(product) {
      if (product.quantity <= 0) {
        return alert('No hay stock disponible de este producto')
      }

      const existingProduct = this.cart.find(p => p.id === product.id)
      
      if (existingProduct) {
        existingProduct.quantity++
      } else {
        this.cart.push({
          ...product,
          stock: product.quantity,
          quantity: 1,
          price: product.prices[0].price,
          quick: false
        })
      }
    },

    onSearchProductsChange() {
      this.$inertia.get('/pos', 
        pickBy({ search: this.form.search }), 
        {
          preserveState: true,
          preserveScroll: true,
          replace: true,
        }
      )
    },

    findClient() {
      this.$inertia.get('/pos', pickBy(this.form), { preserveState: true })

      setTimeout(() => {
        if (this.client) {
          this.textSearch = 'Buscar cliente'
          this.showClientCreate = true
          this.form.client = {
            name: this.client.name,
            email: this.client.email,
            phone: this.client.phone,
            address: this.client.address,
            identification: this.client.identification,
            balance: this.client.balance,
            typeClient: this.client.typeClient,
            companyName: this.client.companyName,
          }
        } else {
          alert('No se encontró el cliente. Puede registrarlo en el formulario.')
          this.showClientCreate = true
        }
      }, 1500)
    },
  },

  mounted() {
    document.getElementById("search").focus()
  }
}
</script>
