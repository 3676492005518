<template>
  <div>
    <!-- Encabezado con título y botones de acción -->
    <Head :title="`Venta #${order.number_order ?? order.id} ${order.order_state === 'cancel' ? '| PEDIDO ANULADO' : ''}`" />

    <div class="flex justify-between items-center mb-6">
      <div class="flex items-center">
        <button 
          class="mr-4 p-3 text-white bg-teal-800 hover:bg-teal-900 rounded transition-colors" 
          @click="back()"
          title="Volver atrás"
        >
          <i class="fa fa-arrow-left"></i>
        </button>
        
        <h1 class="text-3xl font-bold">
          Venta #{{ order.number_order ?? order.id }}
          <span v-if="order.order_state === 'cancel'" class="text-red-500 ml-2">
            PEDIDO ANULADO
          </span>
        </h1>
      </div>

      <!-- Botones de impresión -->
      <div class="flex gap-2">
        <a 
          target="_blank" 
          :href="`/orders/${order.id}/report`"
          class="btn-blue p-3 rounded hover:opacity-90 transition-opacity"
        >
          <i class="fa fa-print text-white mr-2" />
          Presupuesto
        </a>

        <template v-if="order.order_state !== 'estimate'">
          <a 
            target="_blank"
            :href="`/orders/${order.id}/report-storer`" 
            class="btn-blue p-3 rounded hover:opacity-90 transition-opacity"
          >
            <i class="fa fa-print text-white mr-2" />
            Nota de entrega
          </a>

          <a
            target="_blank"
            :href="`/orders/${order.id}/report-invoice`"
            class="btn-blue p-3 rounded hover:opacity-90 transition-opacity"
          >
            <i class="fa fa-print text-white mr-2" />
            Factura
          </a>

          <a
            target="_blank"
            :href="`/pos/${order.id}/show`"
            class="btn-blue p-3 rounded hover:opacity-90 transition-opacity"
          >
            <i class="fa fa-print text-white mr-2" />
            POS
          </a>
        </template>
      </div>
    </div>

    <!-- Información del cliente y detalles de la orden -->
    <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mb-6 p-6 bg-white rounded-lg shadow">
      <div class="space-y-3">
        <div class="grid grid-cols-2 gap-2">
          <div class="font-semibold">Cliente:</div>
          <div>{{ order.client.name }}</div>

          <div class="font-semibold">Alias/Cédula:</div>
          <div>{{ order.client.identification }}</div>

          <div class="font-semibold">Email:</div>
          <div>{{ order.client.email }}</div>

          <div class="font-semibold">Teléfono:</div>
          <div>{{ order.client.phone }}</div>
        </div>

        <div class="border-t pt-3 mt-3">
          <div class="grid grid-cols-2 gap-2">
            <div class="font-semibold">Balance USD:</div>
            <div>${{ order.balance.toFixed(2) }}</div>

            <div class="font-semibold">Balance Bs:</div>
            <div>{{ (order.balance * order.change).toFixed(2) }}</div>

            <div class="font-semibold">Deuda USD:</div>
            <div>${{ (order.total - order.balance).toFixed(2) }}</div>

            <div class="font-semibold">Deuda Bs:</div>
            <div>{{ ((order.total - order.balance) * order.change).toFixed(2) }}</div>

            <div class="font-semibold">Total USD:</div>
            <div>${{ order.total.toFixed(2) }}</div>

            <div class="font-semibold">Total Bs:</div>
            <div>{{ (order.total * order.change).toFixed(2) }}</div>
          </div>
        </div>

        <div class="border-t pt-3 mt-3">
          <div class="grid grid-cols-2 gap-2">
            <div class="font-semibold">Fecha de venta:</div>
            <div>{{ order?.date }}</div>

            <div class="font-semibold">Tienda:</div>
            <div>{{ order?.organization?.name }}</div>

            <div class="font-semibold">Orden de compra:</div>
            <div>{{ order?.order_buy }}</div>

            <div class="font-semibold">Observaciones:</div>
            <div>{{ order?.comentary }}</div>

            <div class="font-semibold">Modalidad:</div>
            <div>{{ order.isCredit ? 'Crédito' : 'Contado' }}</div>

            <div class="font-semibold">Estado:</div>
            <div>
              <span :class="{
                'text-yellow-600': order.order_state === 'estimate',
                'text-red-600': order.order_state === 'cancel',
                'text-orange-600': order.order_state === 'request',
                'text-green-600': order.order_state === 'finish',
                'text-blue-600': order.order_state === 'pay',
                'text-purple-600': order.order_state === 'delivery'
              }">
                {{ getOrderStateLabel(order.order_state) }}
              </span>
            </div>
          </div>
        </div>

        <div v-if="order.order_state === 'estimate'" class="mt-4">
          <button 
            @click="changeOrderState()"
            class="btn-blue px-4 py-2 hover:opacity-90 transition-opacity"
          >
            Procesar orden
          </button>
        </div>
      </div>

      <div class="text-right">
        <div class="font-medium">
          Vendedor: {{ order?.responsible?.first_name }} {{ order?.responsible?.last_name }}
        </div>
      </div>
    </div>

    <!-- Sección de pagos -->
    <div 
      v-if="canManagePayments"
      class="mb-6 p-6 bg-white rounded-lg shadow"
    >
      <h2 class="text-xl font-bold mb-6">Pagos</h2>

      <payment-box 
        v-if="order.order_state !== 'finish'"
        :quick="true"
        :payment_methods="payment_methods"
        :balance="order.total - order.balance"
        :change="order.change"
        :order="order"
      />

      <!-- Tabla de pagos -->
      <div class="mt-6">
        <table class="w-full">
          <thead>
            <tr>
              <th class="py-4 px-6 text-left">Método</th>
              <th class="py-4 px-6 text-left">Monto</th>
              <th class="py-4 px-6 text-left">Fecha</th>
              <th class="py-4 px-6 text-left">Referencia</th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="pay in order.pays" 
              :key="pay.id"
              class="border-t hover:bg-gray-50 transition-colors"
            >
              <td class="py-3 px-6 capitalize">{{ pay.method }}</td>
              <td class="py-3 px-6">
                {{ pay.amount }}
                <span v-if="pay.amount_bs > 0" class="text-gray-600">
                  (BsD. {{pay.amount_bs}})
                </span>
              </td>
              <td class="py-3 px-6">{{ formatDate(pay.created_at) }}</td>
              <td class="py-3 px-6">{{ pay.reference }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Sección de productos -->
    <div class="p-6 bg-white rounded-lg shadow">
      <h2 class="text-xl font-bold mb-6">Productos</h2>

      <div class="overflow-x-auto">
        <table class="w-full">
          <thead>
            <tr>
              <th class="py-4 px-6 text-left"></th>
              <th class="py-4 px-6 text-left">Producto</th>
              <th class="py-4 px-6 text-left">Descripción</th>
              <th class="py-4 px-6 text-left">Depósito</th>
              <th class="py-4 px-6 text-left">Estado</th>
              <th class="py-4 px-6 text-left">Cantidad</th>
              <th class="py-4 px-6 text-left">Precio</th>
              <th class="py-4 px-6 text-left">Total</th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(product, key) in order.products"
              :key="product.id"
              :class="[
                'border-t',
                product.dispatch ? 'bg-blue-50' : '',
                'hover:bg-gray-50 transition-colors'
              ]"
            >
              <td class="py-3 px-6">
                <input
                  v-model="products.products[key]['dispatch']"
                  :value="product.id"
                  type="checkbox"
                  class="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
              </td>
              <td class="py-3 px-6">{{ product.product.name }}</td>
              <td class="py-3 px-6">{{ product.product.description }}</td>
              <td class="py-3 px-6">{{ product.deposit.name }}</td>
              <td class="py-3 px-6">
                <span :class="product.dispatch ? 'text-green-600' : 'text-gray-600'">
                  {{ product.dispatch ? 'Despachado' : 'No Despachado' }}
                </span>
              </td>
              <td class="py-3 px-6">{{ product.quantity }}</td>
              <td class="py-3 px-6">${{ product.price }}</td>
              <td class="py-3 px-6">${{ (product.price * product.quantity).toFixed(2) }}</td>
            </tr>
          </tbody>

          <tfoot>
            <tr class="bg-gray-50 font-semibold">
              <td colspan="6" class="py-3 px-6"></td>
              <td class="py-3 px-6">Total</td>
              <td class="py-3 px-6">${{ order.total }}</td>
            </tr>
          </tfoot>
        </table>
      </div>

      <div class="mt-6">
        <button
          v-if="order.order_state === 'delivery'"
          @click="addDispatch"
          class="px-4 py-2 bg-teal-800 text-white rounded hover:bg-teal-900 transition-colors"
        >
          Despachar productos
        </button>
      </div>
    </div>

    <!-- Sección de despachos -->
    <div 
      v-if="showDispatchSection"
      class="mt-6 p-6 bg-white rounded-lg shadow"
    >
      <h2 class="text-xl font-bold mb-6">Despachos</h2>

      <table class="w-full">
        <thead>
          <tr>
            <th class="py-4 px-6 text-left">Concepto</th>
            <th class="py-4 px-6 text-left">Productos</th>
            <th class="py-4 px-6 text-left">Fecha</th>
            <th class="py-4 px-6 text-left">Responsable</th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="dispatch in dispatches"
            :key="dispatch.id"
            class="border-t hover:bg-gray-50 transition-colors"
          >
            <td class="py-3 px-6">Se realizó el despacho al cliente</td>
            <td class="py-3 px-6">
              {{ dispatch.products.map(p => p.product.name).join(', ') }}
            </td>
            <td class="py-3 px-6">{{ formatDate(dispatch.date) }}</td>
            <td class="py-3 px-6">
              {{ getResponsibleName(dispatch.responsible) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Botón de anular orden -->
    <div 
      v-if="canCancelOrder"
      class="mt-6 p-6 bg-white rounded-lg shadow text-right"
    >
      <button
        @click="cancelOrder"
        class="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
      >
        Anular Orden
      </button>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput.vue'
import PaymentBox from '../../Shared/PaymentBox.vue'

export default {
  name: 'OrderShow',

  components: {
    Head,
    Link,
    TextInput,
    PaymentBox
  },

  layout: Layout,

  props: {
    order: {
      type: Object,
      required: true
    },
    dispatches: {
      type: Object,
      required: true
    },
    payment_methods: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      role: this.$parent.auth.user.role,
      form: this.$inertia.form({
        amount: 0,
        method: '',
        order_id: this.order.id,
        reference: '',
      }),
      products: this.$inertia.form({
        products: this.order.products,
        order_id: this.order.id,
      }),
    }
  },

  computed: {
    canManagePayments() {
      return ['financial', 'admin', 'sales'].includes(this.role) && 
             this.order.order_state !== 'cancel' && 
             this.order.order_state !== 'estimate'
    },

    showDispatchSection() {
      return this.order.order_state !== 'cancel' && 
             this.order.order_state !== 'estimate'
    },

    canCancelOrder() {
      return this.order.pays.length === 0 && 
             this.order.order_state !== 'cancel'
    }
  },

  methods: {
    back() {
      window.history.back()
    },

    cancelOrder() {
      if (confirm('¿Está seguro que desea anular esta orden?')) {
        window.location.href = `/orders/${this.order.id}/cancel`
      }
    },

    addDispatch() {
      this.products.post('/orders/dispatch')
    },

    changeOrderState() {
      this.$inertia.get(`/orders/${this.order.id}/changeState`)
    },

    formatDate(date) {
      return new Date(date).toLocaleDateString('es-ES', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    },

    getOrderStateLabel(state) {
      const labels = {
        estimate: 'PRESUPUESTO',
        cancel: 'ANULADA',
        request: 'PENDIENTE POR PAGO',
        finish: 'FINALIZADA',
        pay: 'PAGADA',
        delivery: 'EN ESPERA DE DESPACHO'
      }
      return labels[state] || state
    },

    getResponsibleName(responsible) {
      if (!responsible) return ''
      return `${responsible.first_name} ${responsible.last_name}`
    }
  }
}
</script>
