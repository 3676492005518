<template>
  <div>
    <Head title="Usuarios" />

    <!-- Header y Barra de herramientas -->
    <div class="flex items-center gap-4 mb-8">
      <h1 class="text-3xl font-bold">Usuarios</h1>
    </div>

    <!-- Filtros y botón crear -->
    <div class="flex items-center justify-between mb-6">
      <search-filter 
        v-model="form.search" 
        class="mr-4 w-full max-w-md" 
        @reset="reset"
      >
        <label class="block text-gray-700 mb-1">Rol:</label>
        <select 
          v-model="form.role" 
          class="form-select w-full rounded-lg border-gray-300 focus:border-blue-500 focus:ring-blue-500"
        >
          <option value="">Todos los roles</option>
          <option value="admin">Administrador</option>
          <option value="sales">Vendedor</option>
          <option value="storer">Almacenista</option>
          <option value="financial">Finanzas</option>
        </select>
      </search-filter>

      <Link 
        class="btn-blue flex items-center gap-2" 
        href="/users/create"
      >
        <i class="fa fa-plus"></i>
        <span>Crear Usuario</span>
      </Link>
    </div>

    <!-- Tabla de usuarios -->
    <div class="bg-white rounded-lg shadow-sm overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr class="text-left bg-gray-50">
            <th class="py-4 px-6 font-semibold">Nombre y Apellido</th>
            <th class="py-4 px-6 font-semibold">Correo electrónico</th>
            <th class="py-4 px-6 font-semibold">Rol</th>
            <th class="py-4 px-6 font-semibold" colspan="2">Tienda</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="user in users" 
            :key="user.id" 
            class="hover:bg-gray-50 focus-within:bg-gray-50"
          >
            <td class="border-t">
              <Link 
                class="flex items-center px-6 py-4 hover:text-blue-600 focus:text-blue-600 transition-colors" 
                :href="`/users/${user.id}/edit`"
              >
                <img 
                  v-if="user.photo" 
                  class="mr-2 w-8 h-8 rounded-full object-cover" 
                  :src="user.photo" 
                  :alt="user.name"
                />
                <span>{{ user.name }}</span>
                <icon 
                  v-if="user.deleted_at" 
                  name="trash" 
                  class="ml-2 w-4 h-4 text-gray-400"
                  title="Usuario eliminado"
                />
              </Link>
            </td>
            <td class="border-t">
              <Link 
                class="block px-6 py-4 hover:text-blue-600"
                :href="`/users/${user.id}/edit`"
              >
                {{ user.email }}
              </Link>
            </td>
            <td class="border-t">
              <Link 
                class="block px-6 py-4 hover:text-blue-600"
                :href="`/users/${user.id}/edit`"
              >
                {{ user.role }}
              </Link>
            </td>
            <td class="border-t">
              <Link 
                class="block px-6 py-4 hover:text-blue-600"
                :href="`/users/${user.id}/edit`"
              >
                {{ user?.organization?.name }}
              </Link>
            </td>
            <td class="w-px border-t">
              <Link 
                class="block p-4 hover:text-blue-600"
                :href="`/users/${user.id}/edit`"
              >
                <icon 
                  name="cheveron-right" 
                  class="w-6 h-6 text-gray-400"
                />
              </Link>
            </td>
          </tr>

          <tr v-if="users.length === 0">
            <td 
              class="px-6 py-8 text-center text-gray-500 border-t" 
              colspan="5"
            >
              No se encontraron usuarios
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import pickBy from 'lodash/pickBy'
import Layout from '@/Shared/Layout'
import throttle from 'lodash/throttle'
import mapValues from 'lodash/mapValues'
import SearchFilter from '@/Shared/SearchFilter'

export default {
  name: 'UsersIndex',

  components: {
    Head,
    Icon,
    Link,
    SearchFilter,
  },

  layout: Layout,

  props: {
    filters: Object,
    users: Array,
  },

  data() {
    return {
      form: {
        search: this.filters.search,
        role: this.filters.role,
        trashed: this.filters.trashed,
      },
    }
  },

  watch: {
    form: {
      deep: true,
      handler: throttle(function () {
        this.$inertia.get('/users', pickBy(this.form), { 
          preserveState: true 
        })
      }, 300),
    },
  },

  methods: {
    reset() {
      this.form = mapValues(this.form, () => null)
    },
  },
}
</script>
