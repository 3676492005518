<template>
  <div>
    <div id="dropdown" />
    <div class="relative">
      <!-- Mobile menu button -->
      <button 
        @click="mobileMenuOpen = !mobileMenuOpen"
        class="md:hidden fixed top-4 right-4 z-20 p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
      >
        <span class="sr-only">Abrir menú</span>
        <svg 
          class="h-10 w-10" 
          :class="{'hidden': mobileMenuOpen, 'block': !mobileMenuOpen }"
          xmlns="http://www.w3.org/2000/svg" 
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
        <svg
          class="h-10 w-10"
          :class="{'block': mobileMenuOpen, 'hidden': !mobileMenuOpen }"
          xmlns="http://www.w3.org/2000/svg"
          fill="none" 
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>

      <!-- Mobile menu -->
      <div 
        :class="{'block': mobileMenuOpen, 'hidden': !mobileMenuOpen}"
        class="md:hidden fixed inset-0 z-10 bg-white"
      >
        <div class="pt-16 pb-6 px-4">
          <main-menu 
            :account="auth.user.account"
            class="flex flex-col space-y-4"
            :style="{'backgroundColor': '#fff'}"
          />
        </div>
      </div>

      <div class="md:flex md:flex-col">
        <div class="md:flex md:flex-col md:h-screen">
          <div class="md:flex md:flex-shrink-0">
            <div class="flex items-center justify-between bg-[#AEF5BC] md:flex-shrink-0 md:justify-center md:w-56 p-4">
              <Link class="mt-1 mb-1 relative" href="/">
                <logo :src="auth.user.account.logo" class="fill-white" width="120" height="50" />
              </Link>
            </div>

            <div class="md:text-md flex items-center justify-between p-4 w-full text-sm bg-white border-b md:px-12 md:py-0">
              <div>
                <span class="badge bg-gray-200 p-1 font-bold rounded-lg text-gray-500">v1.30.2</span>
                <Link href="/taxes" class="mr-4 mt-1 hidden md:block">1$ = {{ change }} BsD</Link>
              </div>
              <div class="font-semibold text-xl"></div>
              <dropdown class="mt-1" placement="bottom-end">
                <template #default>
                  <div class="group flex items-center cursor-pointer select-none">
                    <div class="mr-1 text-gray-700 group-hover:text-blue-600 focus:text-blue-600 whitespace-nowrap">
                      <span>{{ auth.user.first_name }}</span>
                      <span class="hidden md:inline">&nbsp;{{ auth.user.last_name }}</span>
                    </div>
                    <icon class="w-5 h-5 fill-gray-700 group-hover:fill-blue-600 focus:fill-blue-600" name="cheveron-down" />
                  </div>
                </template>
                <template #dropdown>
                  <div class="mt-2 py-2 text-sm bg-white rounded shadow-xl">
                    <Link class="block px-6 py-2 hover:text-white hover:bg-blue-500" :href="`/users/${auth.user.id}/edit`">Mi perfil</Link>
                    <Link v-if="auth.user.role === 'admin'" class="block px-6 py-2 hover:text-white hover:bg-blue-500" href="/users">Usuarios</Link>
                    <Link v-if="auth.user.role === 'admin'" class="block px-6 py-2 hover:text-white hover:bg-blue-500" :href="`/accounts/${auth.user?.account?.id}`">Cuenta</Link>
                    <Link class="block px-6 py-2 w-full text-left hover:text-white hover:bg-blue-500" href="/logout" method="delete" as="button">Cerrar sesión</Link>
                  </div>
                </template>
              </dropdown>
            </div>
          </div>
          <div class="md:flex md:flex-grow md:overflow-hidden">
            <main-menu 
              :account="auth.user.account" 
              class="hidden md:block flex-shrink-0 p-12 w-56 overflow-y-auto rounded-b-lg" 
              :style="{'backgroundColor': auth.user.account.sidebarColor ?? 'white'}" 
            />
            <div class="px-4 py-8 md:flex-1 md:p-12 md:overflow-y-auto" scroll-region>
              <flash-messages />
              <slot />
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<style scoped>
.background {
  background-color: #bbe8e1;
}
</style>

<script>
import { Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Logo from '@/Shared/Logo'
import Dropdown from '@/Shared/Dropdown'
import MainMenu from '@/Shared/MainMenu'
import FlashMessages from '@/Shared/FlashMessages'
import BottomMenu from './BottomMenu.vue'

export default {
  components: {
    Dropdown,
    FlashMessages,
    Icon,
    Link,
    Logo,
    MainMenu,
    BottomMenu
  },
  props: {
    auth: Object,
    change: Number
  },
  data() {
    return {
      date: '',
      time: '',
      mobileMenuOpen: false
    }
  },
  mounted() {
    
  },
  methods: {
    zeroPadding: (num, digit) => {
      var zero = '';
      for(var i = 0; i < digit; i++) {
        zero += '0';
      }
      return (zero + num).slice(-digit);
    },
    update: () => {
      let cd = new Date();
      let hours = '';
      let minutes = '';
      let secounds = '';
      let zero = '';

      for(let i = 0; i < 1; i++) {
        zero += '0';
      }
      hours = cd.getHours() > 9 ? cd.getHours() : (zero + cd.getHours())
      minutes =  cd.getMinutes() > 9 ? cd.getMinutes() : (zero + cd.getMinutes())
      secounds = cd.getSeconds() > 9 ? cd.getSeconds() :(zero + cd.getSeconds())

      return hours + ':' + minutes + ':' + secounds
    },
  }
}
</script>
