<template>
  <div>
    <Head title="Crear categoría de producto" />
    
    <!-- Header -->
    <div class="flex items-center gap-4 mb-8">
      <button 
        class="p-3 text-white bg-teal-700 hover:bg-teal-800 rounded-lg transition-colors"
        title="Volver atrás"
        @click="back()"
      >
        <i class="fa fa-arrow-left"></i>
      </button>
      <h1 class="text-3xl font-bold">
        <Link 
          class="text-teal-600 hover:text-teal-700 transition-colors" 
          href="/product-categories"
        >
          Categorías de Productos
        </Link>
        <span class="text-gray-400 font-medium mx-2">/</span>
        <span>Crear</span>
      </h1>
    </div>

    <!-- Form -->
    <div class="max-w-3xl bg-white rounded-lg shadow-sm border border-gray-200">
      <form @submit.prevent="store">
        <div class="p-8">
          <h2 class="text-lg font-bold mb-4">Información básica</h2>
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <text-input
              v-model="form.name"
              :error="form.errors.name"
              label="Nombre *"
              placeholder="Ingrese el nombre de la categoría"
            />
            <text-input
              v-model="form.code" 
              :error="form.errors.code"
              label="Código"
              placeholder="Ingrese el código de la categoría"
            />
          </div>
        </div>

        <!-- Submit -->
        <div class="flex items-center justify-end px-8 py-4 bg-gray-50 border-t border-gray-100">
          <loading-button 
            :loading="form.processing" 
            class="px-6 py-3 bg-teal-600 hover:bg-teal-700 text-white font-medium rounded-lg transition-colors"
            type="submit"
          >
            Crear categoría
          </loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import LoadingButton from '@/Shared/LoadingButton'

export default {
  components: {
    Head,
    Link,
    LoadingButton,
    TextInput,
  },
  layout: Layout,
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        name: '',
        code: ''
      }),
    }
  },
  methods: {
    back() {
      window.history.back()
    },
    store() {
      this.form.post('/product-categories')
    },
  },
}
</script>