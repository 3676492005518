<template>
  <div>
    <Head title="Gastos" />
    
    <!-- Header -->
    <div class="flex justify-between items-center mb-8">
      <div class="flex items-center gap-4">
        <button 
          class="p-3 text-white bg-teal-700 hover:bg-teal-800 rounded-lg transition-colors"
          title="Volver atrás"
          @click="back()"
        >
          <i class="fa fa-arrow-left"></i>
        </button>
        <h1 class="text-3xl font-bold">Gastos</h1>
      </div>
    </div>

    <!-- Actions -->
    <div class="flex flex-wrap items-center justify-between gap-4 mb-6">
      <Link 
        class="btn-blue flex items-center gap-2" 
        href="/spents/create"
      >
        <i class="fa fa-plus"></i>
        <span>Crear Gasto</span>
      </Link>

      <div class="flex flex-wrap items-center gap-3">
        <button 
          class="btn-blue flex items-center gap-2"
          :class="{'bg-blue-700': searchForm}"
          title="Buscar gastos"
          @click="toggleSearch"
        >
          <i class="fa fa-search"></i>
          <span class="hidden sm:inline">Buscar</span>
        </button>
      </div>
    </div>

    <!-- Search -->
    <div 
      v-show="searchForm"
      class="p-4 mb-6 bg-white rounded-lg shadow-sm border border-gray-200"
    >
      <text-input
        v-model="form.search"
        label="Buscar gastos"
        placeholder="Ingrese el nombre o categoría..."
      />
    </div>

    <!-- Table -->
    <div class="bg-white rounded-lg shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr class="text-left bg-gray-50">
            <th class="py-4 px-6 font-semibold">Nombre</th>
            <th class="py-4 px-6 font-semibold">Categoría</th>
            <th class="py-4 px-6 font-semibold">Fecha</th>
            <th class="py-4 px-6 font-semibold">Monto</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="spent in spents.data" 
            :key="spent.id"
            class="border-t border-gray-100 hover:bg-gray-50 transition-colors"
          >
            <td class="py-4 px-6">
              <Link 
                :href="`/spents/${spent.id}/edit`"
                class="text-gray-600 hover:text-blue-600"
              >
                {{ spent.name }}
              </Link>
            </td>
            <td class="py-4 px-6 text-gray-600">{{ spent.category.name }}</td>
            <td class="py-4 px-6 text-gray-600">{{ new Date(spent.date).toLocaleDateString() }}</td>
            <td class="py-4 px-6 text-gray-600">${{ spent.amount }}</td>
          </tr>
          <tr v-if="!spents.data.length">
            <td 
              class="px-6 py-8 text-center text-gray-500 border-t" 
              colspan="4"
            >
              No hay gastos disponibles
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <pagination 
      v-if="spents.meta.links"
      :links="spents.meta.links" 
      class="mt-6"
    />
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import Pagination from '@/Shared/Pagination'
import throttle from 'lodash/throttle'
import pickBy from 'lodash/pickBy'

export default {
  name: 'SpentsIndex',
  
  components: {
    Head,
    Icon,
    Link,
    Pagination,
    TextInput
  },

  layout: Layout,

  props: {
    filters: Object,
    spents: Object
  },

  data() {
    return {
      searchForm: false,
      form: {
        search: this.filters.search || ''
      }
    }
  },

  watch: {
    form: {
      deep: true,
      handler: throttle(function() {
        this.$inertia.get(
          '/spents',
          pickBy(this.form),
          { preserveState: true }
        )
      }, 300)
    }
  },

  methods: {
    back() {
      window.history.back()
    },

    toggleSearch() {
      this.searchForm = !this.searchForm
      if(this.searchForm) {
        this.$nextTick(() => {
          this.$refs.searchInput?.focus()
        })
      }
    },

    reset() {
      this.form.search = ''
    }
  }
}
</script>
