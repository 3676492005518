<template>
  <div>
    <Head title="Marcas" />
    
    <!-- Header -->
    <div class="flex justify-between items-center mb-8">
      <div class="flex items-center gap-4">
        <button 
          class="p-3 text-white bg-teal-700 hover:bg-teal-800 rounded-lg transition-colors"
          title="Volver atrás"
          @click="back()"
        >
          <i class="fa fa-arrow-left"></i>
        </button>
        <h1 class="text-3xl font-bold">Marcas</h1>
      </div>
    </div>

    <!-- Actions -->
    <div class="flex flex-wrap items-center justify-between gap-4 mb-6">
      <Link 
        class="btn-blue flex items-center gap-2" 
        href="/brands/create"
      >
        <i class="fa fa-plus"></i>
        <span>Crear Marca</span>
      </Link>

      <div class="flex flex-wrap items-center gap-3">
        <button 
          class="btn-blue flex items-center gap-2"
          :class="{'bg-blue-700': searchForm}"
          title="Buscar marcas"
          @click="toggleSearch"
        >
          <i class="fa fa-search"></i>
          <span class="hidden sm:inline">Buscar</span>
        </button>
      </div>
    </div>

    <!-- Search -->
    <div 
      v-show="searchForm"
      class="p-4 mb-6 bg-white rounded-lg shadow-sm border border-gray-200"
    >
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <text-input
          v-model="form.search"
          label="Buscar marcas"
          placeholder="Ingrese el nombre o código de la marca..."
        />
        <div>
          <label class="block text-gray-700 mb-2">Estado:</label>
          <select 
            v-model="form.trashed" 
            class="w-full form-select rounded-lg border-gray-300 focus:border-teal-500 focus:ring-teal-500"
          >
            <option :value="null">Todas las marcas</option>
            <option value="with">Con eliminadas</option>
            <option value="only">Solo eliminadas</option>
          </select>
        </div>
      </div>
    </div>

    <!-- Brands Table -->
    <div class="bg-white rounded-lg shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr class="text-left bg-gray-50">
            <th class="py-4 px-6 font-semibold">Nombre</th>
            <th class="py-4 px-6 font-semibold">Código</th>
            <th class="py-4 px-6 font-semibold">Fecha creación</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="brand in brands.data" 
            :key="brand.id"
            class="border-t border-gray-100 hover:bg-gray-50 transition-colors"
          >
            <td class="py-4 px-6">
              <Link 
                :href="`/brands/${brand.id}/edit`"
                class="text-gray-600 hover:text-blue-600 flex items-center"
              >
                {{ brand.name }}
                <icon 
                  v-if="brand.deleted_at" 
                  name="trash" 
                  class="ml-2 w-4 h-4 text-gray-400"
                />
              </Link>
            </td>
            <td class="py-4 px-6 text-gray-600">{{ brand.code }}</td>
            <td class="py-4 px-6 text-gray-600">
              {{ new Date(brand.created_at).toLocaleDateString() }}
            </td>
          </tr>

          <tr v-if="!brands.data.length">
            <td 
              class="px-6 py-8 text-center text-gray-500 border-t" 
              colspan="3"
            >
              No hay marcas disponibles
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <pagination 
      v-if="brands.links"
      :links="brands.links" 
      class="mt-6"
    />
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import Pagination from '@/Shared/Pagination'
import TextInput from '@/Shared/TextInput'
import throttle from 'lodash/throttle'
import pickBy from 'lodash/pickBy'

export default {
  name: 'BrandsIndex',
  
  components: {
    Head,
    Icon,
    Link,
    Pagination,
    TextInput
  },

  layout: Layout,

  props: {
    filters: Object,
    brands: Object
  },

  data() {
    return {
      searchForm: false,
      form: {
        search: this.filters.search || '',
        trashed: this.filters.trashed || null
      }
    }
  },

  watch: {
    form: {
      deep: true,
      handler: throttle(function() {
        this.$inertia.get(
          '/brands',
          pickBy(this.form),
          { preserveState: true }
        )
      }, 300)
    }
  },

  methods: {
    back() {
      window.history.back()
    },

    toggleSearch() {
      this.searchForm = !this.searchForm
      if(this.searchForm) {
        this.$nextTick(() => {
          this.$refs.searchInput?.focus()
        })
      }
    },

    reset() {
      this.form.search = ''
      this.form.trashed = null
    }
  }
}
</script>
