<template>
  <div>
    <Head title="Productos" />
    
    <!-- Header -->
    <div class="flex justify-between items-center mb-8">
      <div class="flex items-center gap-4">
        <button 
          class="p-3 text-white bg-teal-700 hover:bg-teal-800 rounded-lg transition-colors"
          title="Volver atrás"
          @click="back()"
        >
          <i class="fa fa-arrow-left"></i>
        </button>
        <h1 class="text-3xl font-bold">Productos</h1>
      </div>
    </div>

    <!-- Actions -->
    <div class="flex flex-wrap items-center justify-between gap-4 mb-6">
      <Link 
        class="btn-blue flex items-center gap-2" 
        href="/products/create"
      >
        <i class="fa fa-plus"></i>
        <span>Crear Producto</span>
      </Link>

      <div class="flex flex-wrap items-center gap-3">
        <button 
          class="btn-blue flex items-center gap-2"
          :class="{'bg-blue-700': searchForm}"
          title="Buscar productos"
          @click="toggleSearch"
        >
          <i class="fa fa-search"></i>
          <span class="hidden sm:inline">Buscar</span>
        </button>

        <a 
          href="/products/export" 
          class="btn-blue flex items-center gap-2"
          title="Exportar productos"
        >
          <i class="fa fa-download"></i>
          <span class="hidden sm:inline">Exportar</span>
        </a>

        <div class="relative">
          <button 
            class="btn-blue flex items-center gap-2"
            :class="{'bg-blue-700': imported}"
            @click="toggleImport"
          >
            <i class="fa fa-upload"></i>
            <span class="hidden sm:inline">Importar</span>
          </button>

          <form 
            v-if="imported"
            class="absolute top-full right-0 mt-2 p-4 bg-white rounded-lg shadow-lg"
            action="/products/import" 
            method="post"
            enctype="multipart/form-data"
          >
            <input name="_token" type="hidden" :value="csrf" />
            <div class="flex flex-col gap-3">
              <input 
                type="file" 
                name="file"
                class="block w-full text-sm text-gray-500
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-blue-50 file:text-blue-700
                hover:file:bg-blue-100"
                accept=".csv,.xlsx"
              />
              <button type="submit" class="btn-blue w-full">
                Subir archivo
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Search -->
    <div 
      v-show="searchForm"
      class="p-4 mb-6 bg-white rounded-lg shadow-sm border border-gray-200"
    >
      <text-input
        v-model="form.search.terms"
        label="Buscar productos"
        placeholder="Ingrese código, nombre o descripción..."
        class="w-full lg:w-1/2"
      />
    </div>

    <!-- Quick Links -->
    <div class="mb-8">
      <h3 class="text-xl font-bold mb-4">Auxiliares</h3>
      <div class="flex flex-wrap gap-3">
        <Link 
          href="/product-categories"
          class="flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-lg transition-colors"
        >
          <i class="fa fa-tags"></i>
          Categorías
        </Link>
        <Link
          href="/brands" 
          class="flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-lg transition-colors"
        >
          <i class="fa fa-bookmark"></i>
          Marcas
        </Link>
      </div>
    </div>

    <!-- Products Table -->
    <div class="bg-white rounded-lg shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr class="text-left bg-gray-50">
            <th class="py-4 px-6 font-semibold">Código</th>
            <th class="py-4 px-6 font-semibold">Nombre</th>
            <th class="py-4 px-6 font-semibold">Categoría</th>
            <th class="py-4 px-6 font-semibold">Marca</th>
            <th class="py-4 px-6 font-semibold">Cantidades</th>
            <th class="py-4 px-6 font-semibold">Costo</th>
            <th class="py-4 px-6 font-semibold">Precio</th>
            <th class="py-4 px-6 font-semibold">Precio Bs</th>
            <th class="py-4 px-6 font-semibold">Ganancia</th>
          </tr>
        </thead>
        
        <tbody>
          <tr 
            v-for="product in products.data" 
            :key="product.id"
            class="border-t border-gray-100 hover:bg-gray-50 transition-colors"
          >
            <td class="py-4 px-6">
              <Link 
                :href="`products/${product.id}`"
                class="flex items-center gap-3 text-gray-600 hover:text-blue-600"
              >
                <img 
                  v-if="product.photo"
                  :src="product.photo"
                  :alt="product.name"
                  class="w-8 h-8 rounded-full object-cover"
                />
                <span>{{ product.code }}</span>
              </Link>
            </td>

            <td class="py-4 px-6">
              <Link 
                :href="`products/${product.id}`"
                class="text-gray-600 hover:text-blue-600"
              >
                {{ product.name }}
              </Link>
            </td>

            <td class="py-4 px-6 text-gray-600">
              {{ product?.product_category?.name }}
            </td>

            <td class="py-4 px-6 text-gray-600">
              {{ product?.brand?.name }}
            </td>

            <td class="py-4 px-6">
              <div class="flex flex-wrap gap-2">
                <span 
                  v-for="deposit in product.product_deposits"
                  :key="deposit.id"
                  class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
                >
                  {{ deposit?.deposit?.name }}: {{ deposit?.quantity }}
                </span>
              </div>
            </td>

            <td class="py-4 px-6 text-gray-600">
              {{ formatCurrency(product?.cost) }}
            </td>

            <td class="py-4 px-6 text-gray-600">
              {{ formatCurrency(product?.prices[0]?.price) }}
            </td>

            <td class="py-4 px-6 text-gray-600">
              {{ formatCurrencyBs(product?.prices[0]?.price * change) }}
            </td>

            <td class="py-4 px-6">
              <span 
                :class="[
                  'px-2 py-1 rounded-full text-sm font-medium',
                  getGainClass(product?.prices[0]?.price - product?.cost)
                ]"
              >
                {{ formatCurrency(product?.prices[0]?.price - product?.cost) }}
              </span>
            </td>
          </tr>

          <tr v-if="!products?.data?.length">
            <td 
              class="px-6 py-8 text-center text-gray-500 border-t" 
              colspan="9"
            >
              No hay productos disponibles
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <pagination 
      v-if="products?.meta?.links"
      :links="products.meta.links" 
      class="mt-6"
    />
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import Pagination from '@/Shared/Pagination.vue'
import SearchFilter from '@/Shared/SearchFilter.vue'
import throttle from 'lodash/throttle'
import pickBy from 'lodash/pickBy'
import TextInput from '@/Shared/TextInput.vue'
import SelectInput from '@/Shared/SelectInput.vue'

export default {
  name: 'ProductsIndex',
  
  components: {
    SelectInput,
    TextInput,
    SearchFilter,
    Pagination,
    Head,
    Icon,
    Link,
  },

  layout: Layout,

  props: {
    products: {
      type: Object,
      required: true
    },
    groups: Object,
    csrf: {
      type: String,
      required: true
    },
    brands: Object,
    parts: Object,
    models: Object,
    deposits: Object,
    regions: Object,
    change: {
      type: Number,
      required: true
    },
    cop_change: Number
  },

  data() {
    return {
      searchForm: false,
      imported: false,
      form: this.$inertia.form({
        search: {
          terms: '',
          deposit: '',
          brand_id: '',
          part_id: '',
          truck_model_id: '',
          region_id: '',
        },
      }),
    }
  },

  watch: {
    form: {
      deep: true,
      handler: throttle(function() {
        this.$inertia.get(
          '/products', 
          pickBy(this.form), 
          { preserveState: true }
        )
      }, 300),
    },
  },

  methods: {
    back() {
      window.history.back()
    },

    toggleSearch() {
      this.searchForm = !this.searchForm
      if(this.searchForm) {
        this.$nextTick(() => {
          this.$refs.searchInput?.focus()
        })
      }
    },

    toggleImport() {
      this.imported = !this.imported
    },

    formatCurrency(value) {
      return new Intl.NumberFormat('es-VE', {
        style: 'currency',
        currency: 'USD'
      }).format(value || 0)
    },

    formatCurrencyBs(value) {
      return new Intl.NumberFormat('es-VE', {
        style: 'currency',
        currency: 'VEF'
      }).format(value || 0)
    },

    getGainClass(gain) {
      if(gain > 0) return 'bg-green-100 text-green-800'
      if(gain < 0) return 'bg-red-100 text-red-800'
      return 'bg-gray-100 text-gray-800'
    }
  }
}
</script>
