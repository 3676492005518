<template>
  <div>
    <Head title="Editar gasto" />
    
    <!-- Header -->
    <div class="flex items-center gap-4 mb-8">
      <button 
        class="p-3 text-white bg-teal-700 hover:bg-teal-800 rounded-lg transition-colors"
        title="Volver atrás"
        @click="back()"
      >
        <i class="fa fa-arrow-left"></i>
      </button>
      <h1 class="text-3xl font-bold">
        <Link 
          class="text-teal-600 hover:text-teal-700 transition-colors" 
          href="/spents"
        >
          Gastos
        </Link>
        <span class="text-gray-400 font-medium mx-2">/</span>
        <span>{{ form.name }}</span>
      </h1>
    </div>

    <!-- Form -->
    <div class="max-w-3xl bg-white rounded-lg shadow-sm border border-gray-200">
      <form @submit.prevent="update">
        <div class="p-8">
          <h2 class="text-lg font-bold mb-4">Información del gasto</h2>
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <text-input
              v-model="form.name"
              :error="form.errors.name"
              label="Nombre *"
              placeholder="Ingrese el nombre del gasto"
            />
            <text-input
              v-model="form.date"
              :error="form.errors.date"
              type="date"
              label="Fecha *"
            />
            <text-input
              v-model="form.amount"
              :error="form.errors.amount"
              type="number"
              min="0"
              step="0.01"
              label="Monto *"
              placeholder="0.00"
            />
            <select-input
              v-model="form.spent_category_id"
              :error="form.errors.spent_category_id"
              label="Categoría *"
            >
              <option value="">Seleccionar categoría</option>
              <option
                v-for="category in categories"
                :key="category.id"
                :value="category.id"
              >
                {{ category.name }}
              </option>
            </select-input>
            <select-input
              v-model="form.method"
              :error="form.errors.method"
              label="Método de pago *"
            >
              <option value="">Seleccionar método</option>
              <option value="efectivo">Efectivo</option>
              <option value="transferencia">Transferencia</option>
              <option value="pago-movil">Pago Móvil</option>
              <option value="zelle">Zelle</option>
            </select-input>
          </div>
        </div>

        <div class="p-6 bg-gray-50 border-t flex items-center justify-between">
          <button 
            type="button"
            class="text-red-600 hover:text-red-800 font-medium transition-colors"
            @click="destroy"
          >
            <i class="fa fa-trash mr-2"></i>
            Eliminar gasto
          </button>
          <loading-button 
            :loading="form.processing"
            class="btn-blue"
          >
            <i class="fa fa-save mr-2"></i>
            Actualizar gasto
          </loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'

export default {
  components: {
    Head,
    Link,
    LoadingButton,
    TextInput,
    SelectInput
  },
  layout: Layout,
  props: {
    spent: Object,
    categories: Object
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        name: this.spent?.name,
        amount: this.spent.amount,
        date: this.spent?.date,
        method: this.spent?.method,
        spent_category_id: this.spent.spent_category_id
      }),
    }
  },
  methods: {
    back() {
      this.$inertia.get('/spents')
    },
    update() {
      this.form.put(`/spents/${this.spent.id}`)
    },
    destroy() {
      if (confirm('¿Está seguro que desea eliminar este gasto?')) {
        this.$inertia.delete(`/spents/${this.spent.id}`)
      }
    },
  },
}
</script>