<template>
  <div>
    <Head title="Inventario" />
    
    <!-- Encabezado -->
    <div class="flex items-center justify-between mb-6">
      <button 
        class="mr-2 p-2 text-white bg-teal-800 hover:bg-teal-900 rounded transition-colors" 
        @click="$inertia.get('/inventories')"
        title="Volver al listado"
      >
        <i class="fa fa-arrow-left"></i>
      </button>
      <h1 class="text-3xl font-bold">
        Inventario del {{ getFormattedDate(inventory.created_at) }}
      </h1>
    </div>

    <!-- Tabla de productos -->
    <div class="bg-white rounded-lg shadow-md overflow-hidden mb-6">
      <div class="p-6 border-b">
        <h3 class="text-xl font-semibold">Detalle de Productos</h3>
      </div>

      <div class="overflow-x-auto">
        <table class="w-full whitespace-nowrap">
          <thead>
            <tr class="text-left bg-gray-50">
              <th class="py-3 px-6 font-semibold">Código</th>
              <th class="py-3 px-6 font-semibold">Producto</th>
              <th class="py-3 px-6 font-semibold">Depósito</th>
              <th class="py-3 px-6 font-semibold text-right">Cantidad</th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="product in products.data" 
              :key="product.id" 
              class="hover:bg-gray-50 transition-colors"
            >
              <td class="py-3 px-6">{{ product.product.code }}</td>
              <td class="py-3 px-6">{{ product.product.name }}</td>
              <td class="py-3 px-6">{{ product.deposit.name }}</td>
              <td class="py-3 px-6 text-right font-medium">
                {{ formatQuantity(product.quantity) }}
              </td>
            </tr>
            <tr v-if="!products.data?.length">
              <td colspan="4" class="py-4 px-6 text-center text-gray-500">
                No hay productos registrados
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Botón de aprobación -->
    <div class="flex justify-between items-center">
      <pagination :links="products.links" />
      
      <button 
        v-if="canApprove"
        @click="confirmApproval" 
        class="px-6 py-3 bg-green-600 text-white font-medium rounded-lg hover:bg-green-700 transition-colors"
      >
        <i class="fa fa-check mr-2"></i>
        Confirmar Inventario
      </button>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput.vue'
import Pagination from "@/Shared/Pagination.vue"

export default {
  components: {
    Head,
    Icon,
    Link,
    TextInput,
    Pagination
  },

  layout: Layout,

  props: {
    inventory: {
      type: Object,
      required: true
    },
    products: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },

  computed: {
    canApprove() {
      return this.inventory.status === 'request' && 
             ['storer', 'admin'].includes(this.user.role)
    }
  },

  methods: {
    getFormattedDate(date) {
      return new Date(date).toLocaleString('es-VE', { 
        dateStyle: 'long',
        timeStyle: 'short',
        hour12: false 
      })
    },

    formatQuantity(qty) {
      return new Intl.NumberFormat('es-VE').format(qty)
    },

    confirmApproval() {
      if (confirm('¿Está seguro que desea aprobar este inventario?')) {
        this.$inertia.get(`/inventories/approve/${this.inventory.id}`)
      }
    }
  }
}
</script>
