<template>
  <div>
    <Head title="Reportes" />
    <h1 class="mb-8 text-3xl font-bold">Reportes y Estadísticas</h1>

    <!-- Formulario de generación de reportes -->
    <div class="bg-white p-6 rounded-lg shadow-sm mb-6">
      <h2 class="text-xl font-semibold mb-4">Generar Reporte</h2>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <text-input 
          v-model="form.from" 
          label="Fecha inicial" 
          type="date"
          :error="form.errors.from"
        />
        <text-input 
          v-model="form.to" 
          label="Fecha final" 
          type="date"
          :error="form.errors.to"
        />
        <select-input 
          v-model="form.modality" 
          label="Tipo de reporte"
          :error="form.errors.modality"
        >
          <option value="">Seleccionar tipo</option>
          <option value="advance">Reporte detallado</option>
          <option value="simple">Reporte simple</option>
        </select-input>
        <select-input 
          v-model="form.nulled" 
          label="Incluir anulados"
          :error="form.errors.nulled"
        >
          <option value="">Seleccionar opción</option>
          <option value="1">Incluir anulados</option>
          <option value="0">No incluir anulados</option>
        </select-input>
        <select-input 
          v-model="form.type" 
          label="Formato de descarga"
          :error="form.errors.type"
        >
          <option value="excel">Excel</option>
        </select-input>
        <div class="flex items-end">
          <button 
            class="btn-blue w-full flex items-center justify-center gap-2" 
            @click="generateReport()"
            :disabled="form.processing"
          >
            <i class="fa fa-file-export"></i>
            <span>Generar reporte</span>
          </button>
        </div>
      </div>
    </div>

    <!-- Reportes por depósito -->
    <div class="bg-white p-6 rounded-lg shadow-sm mb-6">
      <h2 class="text-xl font-semibold mb-4">Productos por Depósito</h2>
      <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        <a 
          v-for="deposit in deposits" 
          :key="deposit.id"
          target="_blank" 
          :href="`/products/${deposit.id}/deposits`" 
          class="btn-blue text-center"
        >
          {{ deposit.name }}
        </a>
      </div>
    </div>

    <!-- Reporte de productos -->
    <div class="bg-white p-6 rounded-lg shadow-sm mb-6">
      <h2 class="text-xl font-semibold mb-4">Lista de Productos</h2>
      <button 
        @click="print()" 
        class="btn-blue flex items-center gap-2"
      >
        <i class="fa fa-print"></i>
        <span>Imprimir lista de precios</span>
      </button>
    </div>

    <!-- Link de descarga -->
    <div v-if="count" class="bg-green-50 p-4 rounded-lg mb-6">
      <div class="flex items-center gap-2 text-green-700">
        <i class="fa fa-check-circle"></i>
        <span>¡Reporte generado!</span>
        <a 
          :href="`/orders-${form.orderNumber}.xlsx`"
          class="ml-2 text-green-800 hover:text-green-900 underline"
        >
          Descargar archivo
        </a>
      </div>
    </div>

    <!-- Productos por agotarse -->
    <div v-if="role == 'admin' || role == 'storer'" class="bg-white p-6 rounded-lg shadow-sm">
      <h2 class="text-xl font-semibold mb-4">Productos con Stock Bajo</h2>
      <div class="overflow-x-auto">
        <table class="w-full">
          <thead>
            <tr class="text-left border-b">
              <th class="pb-3 px-4">Código</th>
              <th class="pb-3 px-4">Nombre del Producto</th>
              <th class="pb-3 px-4">Stock Actual</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="prod in productsSoldout" :key="prod.id" class="border-b hover:bg-gray-50">
              <td class="py-3 px-4">{{ prod.code }}</td>
              <td class="py-3 px-4">{{ prod.name }}</td>
              <td class="py-3 px-4">
                <span class="text-red-600 font-medium">{{ prod.quantity }}</span>
              </td>
            </tr>
            <tr v-if="!productsSoldout?.length">
              <td class="p-4 text-center text-gray-500" colspan="3">
                No hay productos con stock bajo en este momento.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput.vue'
import SelectInput from "@/Shared/SelectInput.vue"

export default {
  components: {
    SelectInput,
    TextInput,
    Head,
  },
  layout: Layout,
  props: {
    order: String,
    deposits: {
      type: Object,
      required: true
    },
    role: {
      type: String,
      required: true
    },
    productsSoldout: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      form: this.$inertia.form({
        from: '',
        to: '',
        type: 'excel',
        full: false,
        nulled: '0',
        modality: 'simple',
        orderNumber: this.generateRandomNumber()
      }),
      count: 0
    }
  },
  methods: {
    generateRandomNumber() {
      return Math.floor(Math.random() * (59999 - 10) + 10)
    },
    print() {
      window.open('/products/thermical', 'Precios de productos', 'width=400,height=500,toolbar=no')
    },
    async generateReport() {
      try {
        await this.form.post('/orders/report')
        this.count = 1
        this.form.orderNumber = this.generateRandomNumber()
      } catch (error) {
        console.error('Error al generar reporte:', error)
      }
    }
  }
}
</script>
